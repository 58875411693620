@import "main";

.editable-text {
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
@media screen and (max-width: 1024px) {
  #device-list .hover-large-list{
    display: block;
  }
  #location-list .location-list-item .list-action-items {
    display: block;    
  }
  #scenes .list-action-items{
    display: block !important;
  }
}
@media (min-width: 992px) {
  .device-dp-input {
    background: white;
    color: black;
  }
  .modal-lg {
    width: 900px;
  }
}

@media screen and (max-width: 850px) {
  //This is the width we start telling sidebar to act like it's in mobile mode
  #sidebar-overlay{
    left: $nav-icons-width !important;
  }
  .rangeslider-vertical .rangeslider__handle {
    width: 70px;
    height: 28px;
    left: -20px;
  }
}
@media screen and (max-width: 800px) {
  .menu-title, .menu-title-loc {
    font-size: 1.5rem;
  }
  #location-list .location-list-item .list-action-items {
    display: block;
  }
  #device-list .hover-large-list {
    display: block;
    margin-top: 15px;
    margin-right: 0px; 
    width: 46%;
  }
  #scenes{
    .list-item-text{
      width: 156px;
    }
  }
  #scenes .list-action-items{
    display: block !important;
    width: 166px;
  }
  .location-list-item-2{
    padding-right: 15px !important;
  }
  .secondary-list-item-container {
    margin-right: 0px !important;
  }
  .location-readout-container{
    margin-right: 0px !important;
    span{
      font-size: 16px;
    }
  }
  .location-feature-chevron-container{
    float: right;
  }
  .secondary-list-item-container{
    width: 100%;
    margin-left: 6px;
    float: left;
  }
  .page-content-container{
    height: auto;
    width: auto;
    top: calc(46px + env(safe-area-inset-top)); 
    bottom: 0;
    max-height: 100%;
    position: fixed;
    left: 60px;
    right: 0;
  }
  .loc-name{
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 132px;
  }
  #sync-list{
    .sync-list-container{
      width: 230px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-flex;
    }
    .sync-status-container{
      padding-right: 0px;
    }
  }
}
@media screen and (max-width: 800px) and (min-width: 575px){
  #device-list .hover-large-list{
    width: 40%;
  }
}
@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}

@media screen and (max-width: 660px) {
  #location-data, #sub-location-data {
    line-height: 16px;
  }
  #login{
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 540px) {
  .actionable-item-container{
    li{
      padding-left:5px !important;
      padding-right:5px !important;
    }
  }
  .actionable-list{
    span{
      font-size:13px;
    }
  }
  .actionable-device{
    p{
      font-size:12px !important;
      svg{
        font-size:12px !important;
      }
    }
  }
  #login-form{
    top:100px;
  }
  #page-content{
    padding:10px;
  }
  #sync{
    .menu-title{
      font-size:1.8rem;
    }
  }
  .main-header-container{
    .menu-header{
      height:45px;
    }
    .menu-title{
      font-size: 1.8rem !important;
    }
  }
  .locatoions-main-header{
      background: #4f4f4fd6;
      width: 100%;
      height: 42px;
      padding-top: 10px;
      text-align: center;
      margin-bottom: 0px;
  }
  #root-location-header{
    font-size:1.8rem !important;
  }
  .device-li{
    padding:0px;
  }
  
  .device-li{
    .list-item-content{
      max-width:300px;
    } 
  }
  .device-name{
    font-size:15px;
  }
  #page-content {
    .content-container{
      padding:20px;
    }
  }
  .location-container{
    padding:8px;
    h1{
      padding: 5px 0px 0px 6px;
    }
  }
  .location-empty-state, .empty-state{
    .fa-lightbulb{
      font-size: 25px;
      margin-bottom: 10px;
    }
    .fa-building{
      font-size:28px;
    }
    
  }
  h1{
    font-size:1.8rem;
  }
  h2{
    font-size:1.2rem !important;
  }
  i:hover{
    color:white;
  }
  .stepper-container{
    .finish-button-container{
      width: 100%;
      button{
        margin: auto;
        margin-top: 25px;
      }
    }
  }
  
  .location-feature-list-item{
    display: inline-block !important;
    .location-feature-text-container{
      display: inline-block;
    }
  }
  #remove-component:hover{
    color: white;
  }
  .desktop-header{
    display: none;
  }
  .mobile-header{
    display: block;
    img{
      width: 240px;
      height: auto;
    }
  }
  .page-container{
    .mobile-header{
      display:block;
    }
  }  
  .empty-state{
    // padding-left: 25px;
    // padding-right: 25px;
    padding-top: 70px;
  }
  #landing-page{
    .content-container{
      width: auto;
      margin-top: 200px;
      .logo-container{
        width: 250px;
      }
    }
  }
  #device-list{
    .list-item-content{
      max-width: 300px;
    }
    #hover-large-list{
      max-width: 151px;
    }
    .text-field input{
      max-width: 75px;
    }
    .software{
      width: 100%;
      display: block;
    }
  }
  .direct-controls-container{
    .light-level-text{
      margin-top: 0px;
      h1{
        float: none;
      }
    }
  }
  #site-list-container{
    padding-left: 0px;
    padding-right: 0px;
    $mobile-max-width: 280px;
    .site-list-item:hover{
      background: initial;
    }
    #user-site-list-container{
      max-width: $mobile-max-width;
      #login-user-sites{
        max-height: 350px;
      }
      input{
        font-size: 18px;
      }
    }
  }
  #login{
    padding-left: 0px;
    padding-right: 0px;
    $mobile-max-width: 280px;
    #login-status-error{
      position: absolute;
      top: 0px;
      width: 100%;
      max-width: 100%;
      .error{
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    #login-form{
      input{
        max-width: $mobile-max-width;
        font-size: 18px;
      }
    }
    .submit-button-container{
      margin-top: 15px;
      #site-submit{
        max-width: $mobile-max-width;
        font-size: 22px;
      }
    }
    #app-version {
      position: fixed;
      bottom: 10px;
      color: grey;
      right: 45px;
    }
  }
  .header-crumbs {
    padding: 3px;
    font-size: 11px;
  }
  #tag-device-container {
    height: 232px;
  }
  .loc-features-options {
    top: 1px;
    font-size: 22px;
  }
  #location-title {
    font-size: 24px;
    top: 12px;
  }
  .modal-body {
    height: 91%;
  }
  .modal-dialog {
    width: unset;
    max-width: 98%;
  }
  #ota-channel-select {
    width: 100%;
  }
  #location-title.with-crumbs {
    top: 8px;
  }
  #add-tiny-device-submit {
    padding: 8px 8px;
    font-size: 14px;
  }
  .location-list-item{
    .text-field{
      top: 10px;
      left: 35px;
      position: absolute;
      #location-name-input{
        max-width: 110px;

      }
    }
  }
}

/*           MOBILE OPTIMIZED CSS                   */
@media screen and (max-width: 450px) {
  #sidebar{
    width: 100%;
  }
  #app-menu{
    width: auto;
  }
  div#on-screen-status-error {
    margin-top: 32px;
    font-size: 14px;
    line-height: 18px;
  }
  #nav-icons{
    right: -54px;
  }
  .dev-error-lastping, .dev-error-string {
    padding: 1px 0px;
  }
  .root-location-data {
    padding-left: 59px !important;
  }
  #site-function-select {
    width: 100%;
  }
  .edit-item-list, #edit-device-list {
    top: 16px;
  }
  #advanced-functions {
    float: none;
    margin-bottom: 8px;
  }
  #advanced-function-options {
    float: none;
    width: 86%;
    margin: auto;
  }
  .modal-confirm-message {
    margin-left: 0px;
  }
  #features-list > li > .list-item-content {
    padding-right: 40px;
    top: 4px;
  }
  #features-container {
    padding-top: 39px;
  }
  .loc-settings-dropdown-button {
    margin: 0;
    padding-top: 19px !important;
    height: 53px;
    text-align: center;
    right: -5px !important;
    vertical-align: middle;
    width: 19% !important;
    border-left: 1px solid rgba(31, 32, 31, 0.5);
  }
  .location-template-title {
    margin-bottom: 15px;
  }
  #features-list li {
    padding-top: 0px;
    padding-bottom: 5px;
  }
  .setting-label {
    text-align: left;
    line-height: 28px;
    margin-bottom: 15px;
    margin-top: 30px;
  }
  .feature-settings {
    padding: 21px 16px 30px 16px;
    input[type='range'] {
      margin: 20px 0px 40px 0px;
    }
    > .lock-button {
      top: -30px;
    }
  }
  .device-dp-input {
    background: white;
    color: black;
  }
  .list-item-content {
    width: 84%;
  }
  .sub-menu-dropdown {
    width: 18%;
  }
  .modal-body {
    height: 90%;
  }
  #scenes-modal .modal-body {
    height: 100%;
  }
  #trig-action-container {
    padding-right: 0px;
  }
  .modal-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .action-trig-item-container {
    padding: 5px 7px 2px 13px;
  }
  .occ-indicator {
    left: 84%;
  }
  #advanced-settings-icon {
    position: relative;
    top: 30px;
  }
  .component-name, #breadcrumbs {
    margin-left: 59px;
  }
  #device-title .component-name, .scene-title > .component-name {
    margin-left: 0px;
  }
  .device-main-info {
    text-align: left;
  }
  .menu-button:hover {
    color: white !important;
  }
  #status-error-dropdown {
    top: 17px;
  }
  #locations-header {
    height: 66px;
  }
  #page-content {
    margin-top: 6px;
  }
  #location-title.with-crumbs {
    top: 1px;
    line-height: 1.35;
    margin-top: 6px;
  }
  #breadcrumbs {
    font-size: 12px;
  }
  .independent {
    margin-top: 15px;
  }
  /* line 17, sass/partials/_pages.scss */
  #advanced-device-settings {
    top: 10px;
  }
  .device-data-setting-title {
    display: block;
  }
  #location-data {
    font-size: 10px;
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 2px;
    border-top: 1px solid rgba(79, 79, 79, 0.4);
    background-color: #1f201f;
    margin-top: 10px;
    top: 54px;
    left: 0;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    max-width: 100%;
    line-height: 1.3;
    display: inline-block;
  }
  #sub-location-data {
    font-size: 10px;
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 2px;
    border-top: 1px solid rgba(79, 79, 79, 0.3);
    background-color: #1f201f;
    border-radius: 0px 0px 6px 6px;
    margin-top: 10px;
    top: 5px;
    left: 0;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    max-width: 100%;
    line-height: 1.3;
    display: inline-block;
  }
  #loc-settings-icon {
    font-size: 30px;
    right: -20px;
    top: 1px;
  }
  .loc-header-data {
    display: inline-block;
    width: 33%;
    vertical-align: middle;
  }
 /* #cloud-sync-button {
    height: auto;
    padding: 10px;
  }*/
  #device-sort-by-list {
    top: 41px;
  }
  #tagged-device-search-bar {
    left: 105px;
  }
  #sort-devices {
    h3 {
      padding: 11px 23px 3px 6px;
      top: 3px;
      font-size: .9rem;
      vertical-align: middle;
      letter-spacing: -0.5px;
      height: 34px;
    }
    top: -4px;
    left: 7px;
    white-space: nowrap;
  }
  #advanced-device-settings {
    font-size: 32px;
  }
  .advanced-settings-dropdown {
    top: 80px;
  }
  .sort-by-selected {
    margin-left: 0px;
  }
  #device-sort-by-list span {
    font-size: .8rem;
  }
  #device-info {
    text-align: left;
    padding-top: 10px;
  }
  .template-options select {
    padding: 1px 12px;
  }
  #site-settings-container {
    span, label, h2, h3 {
      font-size: 1.0rem;
    }
  }
  #features-container h3 {
    font-size: 1.0rem;
  }
  .all-features-label {
    font-size: 1.3rem !important;
  }
  #site-settings-container {
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #show-tagged-container > {
    label {
      font-size: 12px;
    }
    input {
      bottom: -2px;
    }
  }
  #add-device-search {
    margin-top: 0px;
    width: 50%;
    margin-left: 19px;
  }
  .success, .error {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 12px;
  }
  .nav-a > i {
    bottom: 7px;
    font-size: 32px;
  }
  #content-area {
    margin-left: 59px;
    padding-top: 7px;
  }
  .nav-a > span {
    font-size: 9px;
  }
  .button-badge {
    top: -22px;
    left: 6px;
    height: 15px;
    width: 15px;
  }
  #device-header {
    height: 57px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 16px;
  }
  .device-sort-by-selected {
    margin-left: 4px;
    letter-spacing: 0px;
  }
  #ident-device-main {
    font-size: 30px;
    top: -4px;
  }
  .tab {
    height: 78%;
  }
  #dimmers-tab {
    letter-spacing: -.4px;
  }
}

@media screen and (max-width: 390px) {
  .device-list-loc {
    font-size: 0.70rem;
  }
  .occ-indicator {
    left: 79%;
  }
  .component-name-input {
    width: 134px;
  }
  .modal-body {
    height: 88%;
  }
  .devices-modal-body {
    height: 82%;
  }
}

@media only screen and (max-height: 530px) {
  #page-wrapper {
    overflow-y: auto;
  }
}

@media (max-height: 1050px) {
  .devices-modal-body {
    height: 85%;
  }
}