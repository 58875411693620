@import "main";

#device-list {
  overflow-y: auto;
  overflow-x: hidden;
  //TOD0: when we add list 167px should be changed to 167px + height of device list
  position: relative;
  padding-bottom: 120px;
  .device-icon{
    top: 3px;
    position: relative;
  }
  .icon-map-marker{
    padding-left: 0px;
  }
  i{
    padding-left: 5px;
    padding-right: 5px;
  }
  .checkbox{
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 4px;
  }
  .list-action-items{
    margin-top: 14px;
    // margin-right: 55px;
    display: inline-block;
    float: right;
    // display: none;
    button{
      height: auto;
      padding: 0px;
    }
    .edit,.add,.delete{
      padding: 7px;
      margin-left: 6px;
      margin-right: 6px;
    }
    .add:hover{
      background-color: #0074d4;
    }
    .delete:hover{
      background-color: #C23030;
    }
    svg{
      color: white;
      font-size: 14px;
    }
  }
  .hover-large-list{
    display: none;
  }
  .device-li:hover > .hover-large-list{
    display: block;
  }
  .text-field input{
    padding-bottom: 0px;
    padding-top: 2px;
    color: #BDBDBD;
    max-width: 130px;
  }
  .blink {
    animation: blinker 1s linear infinite;
  }
  .device-version{
    font-size:12px;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .spin{
    animation: spin-animation $spin-animation-speed infinite;
  }
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  .signal-strength-icon{
    float: right;
    color: white;
    font-size: 16px;
    margin-right: 30px;
    margin-top: 20px;
  }

}

.dropdown-menu {
  background-color: #333333;
}

.occ-indicator > span {
  display: inline-block;
  float: unset;
  margin: 0px 3px;
}

.dropdown-menu > li {
  border-top: 1px solid #595959;
  padding: 3px 5px 1px 19px;
  font-size: 15px;
}

.ident-device {
  cursor: pointer;
  position: relative;
  top: 10px;
  font-size: 25px;
}

.nav-blue {
  background: #0074d4;
}

.nav-grey {
  background: #595959;
}

.device-type-tab-bar {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #696969;
}

.tab {
  /* margin-right: 4px; */
  /* margin-right: 4px; */
  text-align: center;
  font-size: 14px;
  padding-top: 4px;
  color: white;
  border-radius: 4px 4px 0px 0px;
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
  position: relative;
  background: #4f4f4fd6;
  border-bottom: none;
  width: 19.99%;
  line-height: 1.3;
  height: 74%;
  margin-top: 11px;
  cursor: pointer;
}

.tab-selected {
  background: #696969;  
  box-shadow: (-3px) 0px 1px 0px rgba(0, 0, 0, 0.21), 2px -1px 1px 0px rgba(0, 0, 0, 0.21);
  cursor: pointer;
  z-index: 999;
  -webkit-transition: all .2s;
  -webkit-backface-visibility: hidden;
}

.device-count {
  font-size: 10px;
  display: block;
}

.hide-scene-button, .show-scene-button {
  cursor: pointer;
  position: absolute;
  padding: 8px;
  top: 5px;
  z-index: 2;
  color: white;
}

.scene-list-item > .list-item-content {
  padding-top: 5px;
}

.hide-scene-button.icon-eye-close {
  color: #cacaca;
}

.item-select {
  font-size: 12px;
  cursor: pointer;
  padding-right: 5px;
}

//BC were handling the device list with react now
#device-list .item-select{
  display: inline;
  font-size: 12px;
  cursor: pointer;
  padding-right: 5px; 
}

.scene-list-item .item-select {
  padding-right: 8px;
}

.edit-icon {
  display: inline;
  font-size: 11px;
  vertical-align: super;
  padding: 18px 15px 18px 15px;
  right: 8px;
  position: relative;
}
.edit-icon-device{
  display: inline;
  font-size: 11px;
  vertical-align: super;
  padding: 18px 15px 18px 15px !important;
  right: 8px;
  position: relative; 
}

.scene-list-item {
  line-height: 1.6;
  position: relative;
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 8px;
  border-bottom: 1px solid #0374d4;
}

.link-id {
  font-size: 13px;
}

.header-crumbs {
  font-size: 13px;
  padding: 5px;
  color: white;
}

.menu-settings {
  position: absolute;
  width: 100%;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px -1px 2px rgb(37, 37, 37);
  height: 50px;
  bottom: -52px;
  color: white;
  p {
    font-size: 10px;
  }
}

.menu-setting-individual-container {
  display: inline-block;
  position: absolute;
  top: 9px;
  width: 13%;
  p {
    line-height: 11px;
    font-size: 10px;
    letter-spacing: 0.9px;
  }
}

.right-menu-settings {
  right: 5px;
}

.left-menu-settings {
  left: 12px;
}

.center-menu-settings {
  margin-right: auto;
  margin-left: auto;
  left: 0px;
  right: 0px;
}

#menu-settings i {
  height: 100%;
  font-size: 1.6rem;
  position: relative;
  cursor: pointer;
}

#open-menu-settings {
  font-size: 25px;
}

.menu-button {
  cursor: pointer;
  color: white;
  padding: 10px 20px 10px 20px;
}

.scene-count {
  font-size: 0.7rem;
  color: white;
}

.menu {
  background-color: #333333;
  z-index: 6;
  font-size: 18px;
  width: 99%;
  padding-top: 57px;
  padding-bottom: 0;
  color: white;
  overflow: auto;
  display: none;
  position: relative;
  min-width: 350px;
  max-width: 600px;
  max-height: 97.5;
  border-radius: 3px;
  overflow-x: hidden;
}

.menu-right {
  &::before {
    box-shadow: inset 2px 0px 4px 1px rgb(22, 22, 22);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
  right: -100%;
  position: absolute;
}

.menu-left {
  &::before {
    box-shadow: inset -2px 0px 4px 1px rgb(22, 22, 22);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
  left: -100%;
  position: absolute;
}

.menu > li, .bottom-menu-list > li {
  padding: 4px 0px 4px 3px;
  line-height: 2.5;
  border-bottom: 1px solid rgb(32, 32, 32);
}

.sub-menu {
  display: none;
  border-radius: 2px;
  width: 100%;
  z-index: 15;
  box-shadow: inset 1px 1px 1px 0px  rgba(31, 31, 31, 0.67);
}


.sub-menu-dropdown {
  text-align: center;
  height: 42px;
  width: 14%;
  box-shadow: inset 1px 0px 0px rgba(30, 32, 30, 0.28);
  position: absolute;
  padding: 15px 0px 15px 0px;
  font-size: 15px;
  border-radius: 0px;
  right: 0px;
  &.selected {
    height: 44px;
    box-shadow: inset 0px 1px 0px 0px rgba(30, 32, 30, 0.33), inset 1px 0px 0px rgba(30, 32, 30, 0.33), inset 0px 1px 0px rgba(30, 32, 30, 0.07);
  }
}

.sub-menu>.loc-list-li>.sub-menu-dropdown {
  box-shadow: inset 1px 0px 0px rgba(30, 32, 30, 0.33);
  
}
.sub-menu>.loc-list-li:first-child>.sub-menu-dropdown{
  box-shadow: inset 1px 0px 0px rgba(30, 32, 30, 0.33), inset 0px 1px 0px rgba(30, 32, 30, 0.33);
}

.sub-menu > li {
  border-bottom: 1px solid rgba(32, 32, 32, 0.35);
  &:last-child {
    border-bottom: none;
  }
}

.scene-list-loc {
  position: relative;
  border-bottom: 1px solid #0374d4;
}

.list-item-content {
  // width: 87%;
  cursor: pointer;
  position: relative;
  color: white;
  display: inline-block;
  padding-left: 10px;
  border-radius: 2px;
}

/* #main-location-list .list-item-content{
    width: auto;
}  */

#bottom-menu {
  background: #09518d;
  width: 100%;
  max-height: 650px;
  height: 96%;
  bottom: -600px;
  position: absolute;
  white-space: nowrap;
  z-index: 7;
  box-shadow: 0px -1px 2px 0px rgb(0, 0, 0);
  border-bottom: none;
}

.bottom-menu-container {
  height: 98%;
  display: none;
  background: #025da9;
  padding: 37px 0px 0px 0px;
  z-index: 3;
  box-shadow: 0px -1px 0px #202020;
  width: 100%;
}

.contained-menu-button {
  display: inline-block;
  position: relative;
  border-radius: 1px;
  width: 50%;
  height: 50px;
  line-height: 2.7;
  font-size: 1.7vh;
  position: relative;
  color: white;
  text-align: center;
  z-index: 5;
  &.selected {
    background: #025da9;
    color: #a6b9ff;
    box-shadow: 0px -1px 3px #011b35;
  }
  &:hover {
    color: #a6b9ff;
  }
}

.bottom-menu-list {
  width: 100%;
  color: white;
  height: 100%;
  text-align: left;
  padding-bottom: 40px;
  z-index: 3;
  position: relative;
  font-size: 18px;
  background-color: #025da9;
  overflow: auto;
}

.group-select-list-item:hover {
  background: #0466b9;
}

.show-group-add {
  display: none;
  position: absolute;
  right: 10%;
  font-size: 13px;
}

.add-devices-to-group-tag {
  font-size: 18px;
  margin: 7px;
}

.group-select-list-item:hover .show-group-add {
  display: inline-block;
}

#select-devices-please {
  display: none;
  margin: 10px;
  font-weight: bold;
}
.group-list{
  margin-left:15px;
}
.group-item {
  display: inline-block;
  margin-right: 0px;
  font-size:12px;

  .group-tag-icon {
    margin-right: 3px;
    font-size:11px;
  }
}

.bottom-menu-li {
  position: relative;
  border-bottom: 1px solid #01467e;
  cursor: pointer;
}

#site-options-menu, #main-location-list {
  height: 100%;
}

.app-menu > ul {
  max-height: 93%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#site-options-menu > li {
  padding: 10px 0px 10px 10px;
}

ul {
  &#site-options-menu > li > title, > li > div > title {
    display: inline-block;
    font-size: 18px;
  }
}

.site-menu-switch {
  float: right;
}

#admin-controls > li {
  padding: 5px 0px 5px 15px;
}

.menu-closed {
  display: none;
}

.menu-open {
  display: block !important;
}

.dropdown-icon {
  position: relative;
  font-size: 15px;
}

#app-menu {
  // left: -440px;
  // position: fixed;
  width: 84vw;
  max-width: 440px;
  z-index: 50;
  height: 100%;
  left: 0px;
  background-color: #696969;
}

#no-scenes-display {
  position: relative;
  word-wrap: normal;
  padding: 10px;
  color: white;
  background: #047ee5;
  border-radius: 5px;
  margin: 14px;
  cursor: pointer;
}

.menu-tab {
  cursor: pointer;
}

.device-list-groups {
  position: relative;
  font-size: 12px;
  color: white;
  left: 10px;
  line-height: 1.6;
  max-width: 80%;
}

.icon-qrcode {
  left: 14px;
  top: 11px;
  z-index: 999;
  padding: 5px;
  font-size: 1.7rem;
  color: white;
  cursor: pointer;
  position: absolute;
}

.device-li {
  border-bottom: .5px solid #4f4f4fd6;
  position: relative;
  padding: 0px 0px 2px 0px;
  > .list-item-content {
    line-height: 1.4;
    padding: 10px 5px 5px 6px;
  }
  .icon{
    font-size:12px;
  }
}

.device-rssi {
  top: 34%;
  color: white;
  position: absolute;
  cursor: pointer;
  padding: 5px 10px 8px 7px;
}

.device-list-loc {
  display: block;
  left: 25px;
  max-width: 100%;
  position: relative;
  span,svg{
    margin-right: 4px;
  }
}
.phd-output-item{
  .device-list-loc{
    left:33px;
  }
}

.device-name {
  /* padding-top: 25px; */
  padding-right: 5px;
  margin-left: 5px;
  position: relative;
  /* font-size: 20px; */
  line-height: 1;
}

.scene-loc-info {
  position: relative;
  display: block;
  line-height: 1;
  font-size: .75rem;
  padding-left: 2px;
}

.menu-title {
  font-size: 2rem;
  margin: 0;
}

.menu-label {
  color: white;
  display: block;
  font-size: 1.5rem;
  cursor: pointer;
  text-align: center;
  padding-bottom: 25px;
  &:hover {
    color: #a6b9ff;
  }
}

.device-list-identifier {
  position: absolute;
  right: 10px;
  padding: 16px 20px 11px 20px;
  color: white;
  display: inline-block !important;
}

#tag-device-container {
  padding: 3px;
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #535353;
  #device-list{
    padding-bottom: 0px;
  }
}

.device-li.located {
  display: none;
}

#add-device-list {
  position: relative;
  height: 85%;
  overflow: auto;
  border-radius: 5px;
  border: 1 px solid darkblue;
  > li {
    > .list-item-content > {
      .device-id {
        font-size: 13px;
      }
      .device-name {
        padding-right: 2px;
      }
    }
    &:hover .add-this {
      display: inline-block;
    }
    > .add-this {
      display: none;
      position: absolute;
      right: 70px;
      padding: 16px 20px 11px 20px;
      color: white;
      font-size: 18px;
    }
  }
}

#show-tagged-container {
  display: inline-block;
  position: relative;
  background: #4f4f4fd6;
  padding: 3px 12px 2px 8px;
  border-radius: 5px;
  cursor: pointer;
  left: 6px;

  > {
    label {
      display: inline-block;
      position: relative;
      font-size: 15px;
    }
    input {
      display: inline-block;
      position: relative;
      width: 14px;
      padding: 10px;
      bottom: -4px;
      margin-left: 10px;
    }
  }
}

.device-icon-container {
  position: relative;
  width: 15px;
  text-align: center;
  top: 1px;
  margin-right: 4px;
}

#add-device-search {
  width: 60%;
  margin-left: 25px;
  display: inline-block;
  border-radius: 5px;
  border: 4px solid white;
  margin-bottom: 10px;
}

#add-device-list > li > .list-item-content {
  padding: 5px 10px 5px 0px;
  line-height: 0.9;
}

#no-scenes-display > p > .add-scene {
  position: relative;
  top: unset;
  left: unset;
}
.select-output-label{
  color: white;
  position: absolute;
  right: 0;
  top: 20px;
}
.phd-output-select-container{
  width: 165px;
  max-height: 509px;
}

.phd-ouput-item{
  cursor:pointer;
  
}
.phd-ouput-item{
  p:hover{
    color:grey;
  }
 
}
/*.menu-button.selected{
    box-shadow: inset 0px 0px 9px black;
}
.menu-button.selected>label{
    color:#a6b9ff;
}*/
