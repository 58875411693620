body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#main-location-list {
  overflow: auto;
  overflow-x: hidden; }

.location-page-info {
  margin-left: 8px; }

.sub-menu > .loc-list-li {
  padding-left: 10px; }

.nav-a > span {
  bottom: 0;
  position: absolute;
  left: 2px;
  text-align: center;
  height: 15px;
  line-height: 1.3;
  font-size: 10px;
  /* background-color: #0374d4; */
  letter-spacing: 0.7px;
  width: 100%; }

.nav-a > i {
  font-size: 38px;
  bottom: -3px;
  position: relative; }

#remove-component:hover {
  color: #e85252; }

.list-item-content.active > .loc-name {
  color: #a6b9ff; }

.add-loc-button {
  position: absolute;
  right: 2%;
  padding: 10px;
  color: white;
  top: 2px;
  font-size: 1.2rem; }

.loc-menu-setting {
  display: none;
  position: absolute;
  font-size: 3rem;
  color: white; }

#login-icon, .add-device {
  font-size: 1.7rem;
  color: white;
  cursor: pointer;
  top: 15px;
  left: 4%;
  position: absolute; }

.add-device {
  top: 6px;
  left: 0px; }

.edit-item-list, #edit-device-list {
  padding: 3px;
  font-size: 21px;
  top: 16px;
  cursor: pointer;
  color: white;
  right: 4%;
  position: absolute;
  z-index: 99; }

#login {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 10000;
  background-color: #595959;
  box-shadow: 0px 1px 2px #3b3838; }

#login a {
  display: block;
  color: white; }

#login-form {
  position: relative;
  top: 25px; }

#login-header {
  position: relative;
  color: white;
  text-align: center;
  padding-top: 15px !important;
  font-size: 28px; }

#site-submit {
  width: 100px;
  height: 50px;
  border-radius: 3px;
  line-height: 1;
  color: white; }

#set-target-light-level {
  width: 100px;
  height: 50px;
  border-radius: 3px;
  line-height: 1;
  background-color: #0074d4;
  color: white;
  padding: 15px;
  font-size: 16px;
  display: none; }

#set-target-light-level:hover {
  text-decoration: none; }

#site-submit:hover {
  color: #a6b9ff; }

#site-submit:active {
  box-shadow: inset 0px 0px 4px black;
  background-color: #313336; }

input#site-email, #site-pass {
  height: 50px;
  border-radius: 3px; }

.loc-list-item {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%; }

.loc-list-li {
  position: relative;
  line-height: 2.5;
  text-align: left;
  left: 0px;
  cursor: pointer;
  border-bottom: 1px solid rgba(65, 64, 64, 0.63);
  list-style: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.3s;
  transition: left 0.3s; }

.loc-list-li > .loc-name {
  padding: 0px 20px 0px 10px; }

.level-1 {
  background-color: #696969; }

.level-2 {
  background-color: #5c5c5c; }

.level-3 {
  background-color: #515151; }

.occ-indicator {
  position: absolute;
  left: 88%;
  top: 1px;
  color: white;
  text-align: right;
  /* word-wrap: initial; */
  /* line-break: unset; */
  font-size: .83rem !important;
  width: 85px; }

.loc-list-li > .loc-has-children {
  position: absolute;
  top: 15px;
  left: 82%; }

.child-loc-ul {
  position: relative;
  margin-left: 0px;
  width: 100%;
  padding-left: 17px; }

.child-loc-ul {
  display: none; }

#daylighting {
  padding-top: 20px;
  padding-bottom: 10px; }

.config-tables {
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 1px; }

.top-readout, .bottom-readout {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 1px;
  max-height: 300px; }

.device-detail-header {
  color: black;
  font-size: 16px; }


#device-list {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 120px; }
  #device-list .device-icon {
    top: 3px;
    position: relative; }
  #device-list .icon-map-marker {
    padding-left: 0px; }
  #device-list i {
    padding-left: 5px;
    padding-right: 5px; }
  #device-list .checkbox {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 4px; }
  #device-list .list-action-items {
    margin-top: 14px;
    display: inline-block;
    float: right; }
    #device-list .list-action-items button {
      height: auto;
      padding: 0px; }
    #device-list .list-action-items .edit, #device-list .list-action-items .add, #device-list .list-action-items .delete {
      padding: 7px;
      margin-left: 6px;
      margin-right: 6px; }
    #device-list .list-action-items .add:hover {
      background-color: #0074d4; }
    #device-list .list-action-items .delete:hover {
      background-color: #C23030; }
    #device-list .list-action-items svg {
      color: white;
      font-size: 14px; }
  #device-list .hover-large-list {
    display: none; }
  #device-list .device-li:hover > .hover-large-list {
    display: block; }
  #device-list .text-field input {
    padding-bottom: 0px;
    padding-top: 2px;
    color: #BDBDBD;
    max-width: 130px; }
  #device-list .blink {
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite; }
  #device-list .device-version {
    font-size: 12px; }

@-webkit-keyframes blinker {
  50% {
    opacity: 0; } }

@keyframes blinker {
  50% {
    opacity: 0; } }
  #device-list .spin {
    -webkit-animation: spin-animation 0.8s infinite;
            animation: spin-animation 0.8s infinite; }

@-webkit-keyframes spin-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

@keyframes spin-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }
  #device-list .signal-strength-icon {
    float: right;
    color: white;
    font-size: 16px;
    margin-right: 30px;
    margin-top: 20px; }

.dropdown-menu {
  background-color: #333333; }

.occ-indicator > span {
  display: inline-block;
  float: unset;
  margin: 0px 3px; }

.dropdown-menu > li {
  border-top: 1px solid #595959;
  padding: 3px 5px 1px 19px;
  font-size: 15px; }

.ident-device {
  cursor: pointer;
  position: relative;
  top: 10px;
  font-size: 25px; }

.nav-blue {
  background: #0074d4; }

.nav-grey {
  background: #595959; }

.device-type-tab-bar {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #696969; }

.tab {
  /* margin-right: 4px; */
  /* margin-right: 4px; */
  text-align: center;
  font-size: 14px;
  padding-top: 4px;
  color: white;
  border-radius: 4px 4px 0px 0px;
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
  position: relative;
  background: #4f4f4fd6;
  border-bottom: none;
  width: 19.99%;
  line-height: 1.3;
  height: 74%;
  margin-top: 11px;
  cursor: pointer; }

.tab-selected {
  background: #696969;
  box-shadow: -3px 0px 1px 0px rgba(0, 0, 0, 0.21), 2px -1px 1px 0px rgba(0, 0, 0, 0.21);
  cursor: pointer;
  z-index: 999;
  -webkit-transition: all .2s;
  -webkit-backface-visibility: hidden; }

.device-count {
  font-size: 10px;
  display: block; }

.hide-scene-button, .show-scene-button {
  cursor: pointer;
  position: absolute;
  padding: 8px;
  top: 5px;
  z-index: 2;
  color: white; }

.scene-list-item > .list-item-content {
  padding-top: 5px; }

.hide-scene-button.icon-eye-close {
  color: #cacaca; }

.item-select {
  font-size: 12px;
  cursor: pointer;
  padding-right: 5px; }

#device-list .item-select {
  display: inline;
  font-size: 12px;
  cursor: pointer;
  padding-right: 5px; }

.scene-list-item .item-select {
  padding-right: 8px; }

.edit-icon {
  display: inline;
  font-size: 11px;
  vertical-align: super;
  padding: 18px 15px 18px 15px;
  right: 8px;
  position: relative; }

.edit-icon-device {
  display: inline;
  font-size: 11px;
  vertical-align: super;
  padding: 18px 15px 18px 15px !important;
  right: 8px;
  position: relative; }

.scene-list-item {
  line-height: 1.6;
  position: relative;
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 8px;
  border-bottom: 1px solid #0374d4; }

.link-id {
  font-size: 13px; }

.header-crumbs {
  font-size: 13px;
  padding: 5px;
  color: white; }

.menu-settings {
  position: absolute;
  width: 100%;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px -1px 2px #252525;
  height: 50px;
  bottom: -52px;
  color: white; }
  .menu-settings p {
    font-size: 10px; }

.menu-setting-individual-container {
  display: inline-block;
  position: absolute;
  top: 9px;
  width: 13%; }
  .menu-setting-individual-container p {
    line-height: 11px;
    font-size: 10px;
    letter-spacing: 0.9px; }

.right-menu-settings {
  right: 5px; }

.left-menu-settings {
  left: 12px; }

.center-menu-settings {
  margin-right: auto;
  margin-left: auto;
  left: 0px;
  right: 0px; }

#menu-settings i {
  height: 100%;
  font-size: 1.6rem;
  position: relative;
  cursor: pointer; }

#open-menu-settings {
  font-size: 25px; }

.menu-button {
  cursor: pointer;
  color: white;
  padding: 10px 20px 10px 20px; }

.scene-count {
  font-size: 0.7rem;
  color: white; }

.menu {
  background-color: #333333;
  z-index: 6;
  font-size: 18px;
  width: 99%;
  padding-top: 57px;
  padding-bottom: 0;
  color: white;
  overflow: auto;
  display: none;
  position: relative;
  min-width: 350px;
  max-width: 600px;
  max-height: 97.5;
  border-radius: 3px;
  overflow-x: hidden; }

.menu-right {
  right: -100%;
  position: absolute; }
  .menu-right::before {
    box-shadow: inset 2px 0px 4px 1px #161616;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ""; }

.menu-left {
  left: -100%;
  position: absolute; }
  .menu-left::before {
    box-shadow: inset -2px 0px 4px 1px #161616;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ""; }

.menu > li, .bottom-menu-list > li {
  padding: 4px 0px 4px 3px;
  line-height: 2.5;
  border-bottom: 1px solid #202020; }

.sub-menu {
  display: none;
  border-radius: 2px;
  width: 100%;
  z-index: 15;
  box-shadow: inset 1px 1px 1px 0px rgba(31, 31, 31, 0.67); }

.sub-menu-dropdown {
  text-align: center;
  height: 42px;
  width: 14%;
  box-shadow: inset 1px 0px 0px rgba(30, 32, 30, 0.28);
  position: absolute;
  padding: 15px 0px 15px 0px;
  font-size: 15px;
  border-radius: 0px;
  right: 0px; }
  .sub-menu-dropdown.selected {
    height: 44px;
    box-shadow: inset 0px 1px 0px 0px rgba(30, 32, 30, 0.33), inset 1px 0px 0px rgba(30, 32, 30, 0.33), inset 0px 1px 0px rgba(30, 32, 30, 0.07); }

.sub-menu > .loc-list-li > .sub-menu-dropdown {
  box-shadow: inset 1px 0px 0px rgba(30, 32, 30, 0.33); }

.sub-menu > .loc-list-li:first-child > .sub-menu-dropdown {
  box-shadow: inset 1px 0px 0px rgba(30, 32, 30, 0.33), inset 0px 1px 0px rgba(30, 32, 30, 0.33); }

.sub-menu > li {
  border-bottom: 1px solid rgba(32, 32, 32, 0.35); }
  .sub-menu > li:last-child {
    border-bottom: none; }

.scene-list-loc {
  position: relative;
  border-bottom: 1px solid #0374d4; }

.list-item-content {
  cursor: pointer;
  position: relative;
  color: white;
  display: inline-block;
  padding-left: 10px;
  border-radius: 2px; }

/* #main-location-list .list-item-content{
    width: auto;
}  */
#bottom-menu {
  background: #09518d;
  width: 100%;
  max-height: 650px;
  height: 96%;
  bottom: -600px;
  position: absolute;
  white-space: nowrap;
  z-index: 7;
  box-shadow: 0px -1px 2px 0px black;
  border-bottom: none; }

.bottom-menu-container {
  height: 98%;
  display: none;
  background: #025da9;
  padding: 37px 0px 0px 0px;
  z-index: 3;
  box-shadow: 0px -1px 0px #202020;
  width: 100%; }

.contained-menu-button {
  display: inline-block;
  position: relative;
  border-radius: 1px;
  width: 50%;
  height: 50px;
  line-height: 2.7;
  font-size: 1.7vh;
  position: relative;
  color: white;
  text-align: center;
  z-index: 5; }
  .contained-menu-button.selected {
    background: #025da9;
    color: #a6b9ff;
    box-shadow: 0px -1px 3px #011b35; }
  .contained-menu-button:hover {
    color: #a6b9ff; }

.bottom-menu-list {
  width: 100%;
  color: white;
  height: 100%;
  text-align: left;
  padding-bottom: 40px;
  z-index: 3;
  position: relative;
  font-size: 18px;
  background-color: #025da9;
  overflow: auto; }

.group-select-list-item:hover {
  background: #0466b9; }

.show-group-add {
  display: none;
  position: absolute;
  right: 10%;
  font-size: 13px; }

.add-devices-to-group-tag {
  font-size: 18px;
  margin: 7px; }

.group-select-list-item:hover .show-group-add {
  display: inline-block; }

#select-devices-please {
  display: none;
  margin: 10px;
  font-weight: bold; }

.group-list {
  margin-left: 15px; }

.group-item {
  display: inline-block;
  margin-right: 0px;
  font-size: 12px; }
  .group-item .group-tag-icon {
    margin-right: 3px;
    font-size: 11px; }

.bottom-menu-li {
  position: relative;
  border-bottom: 1px solid #01467e;
  cursor: pointer; }

#site-options-menu, #main-location-list {
  height: 100%; }

.app-menu > ul {
  max-height: 93%;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

#site-options-menu > li {
  padding: 10px 0px 10px 10px; }

ul#site-options-menu > li > title, ul > li > div > title {
  display: inline-block;
  font-size: 18px; }

.site-menu-switch {
  float: right; }

#admin-controls > li {
  padding: 5px 0px 5px 15px; }

.menu-closed {
  display: none; }

.menu-open {
  display: block !important; }

.dropdown-icon {
  position: relative;
  font-size: 15px; }

#app-menu {
  width: 84vw;
  max-width: 440px;
  z-index: 50;
  height: 100%;
  left: 0px;
  background-color: #696969; }

#no-scenes-display {
  position: relative;
  word-wrap: normal;
  padding: 10px;
  color: white;
  background: #047ee5;
  border-radius: 5px;
  margin: 14px;
  cursor: pointer; }

.menu-tab {
  cursor: pointer; }

.device-list-groups {
  position: relative;
  font-size: 12px;
  color: white;
  left: 10px;
  line-height: 1.6;
  max-width: 80%; }

.icon-qrcode {
  left: 14px;
  top: 11px;
  z-index: 999;
  padding: 5px;
  font-size: 1.7rem;
  color: white;
  cursor: pointer;
  position: absolute; }

.device-li {
  border-bottom: .5px solid #4f4f4fd6;
  position: relative;
  padding: 0px 0px 2px 0px; }
  .device-li > .list-item-content {
    line-height: 1.4;
    padding: 10px 5px 5px 6px; }
  .device-li .icon {
    font-size: 12px; }

.device-rssi {
  top: 34%;
  color: white;
  position: absolute;
  cursor: pointer;
  padding: 5px 10px 8px 7px; }

.device-list-loc {
  display: block;
  left: 25px;
  max-width: 100%;
  position: relative; }
  .device-list-loc span, .device-list-loc svg {
    margin-right: 4px; }

.phd-output-item .device-list-loc {
  left: 33px; }

.device-name {
  /* padding-top: 25px; */
  padding-right: 5px;
  margin-left: 5px;
  position: relative;
  /* font-size: 20px; */
  line-height: 1; }

.scene-loc-info {
  position: relative;
  display: block;
  line-height: 1;
  font-size: .75rem;
  padding-left: 2px; }

.menu-title {
  font-size: 2rem;
  margin: 0; }

.menu-label {
  color: white;
  display: block;
  font-size: 1.5rem;
  cursor: pointer;
  text-align: center;
  padding-bottom: 25px; }
  .menu-label:hover {
    color: #a6b9ff; }

.device-list-identifier {
  position: absolute;
  right: 10px;
  padding: 16px 20px 11px 20px;
  color: white;
  display: inline-block !important; }

#tag-device-container {
  padding: 3px;
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #535353; }
  #tag-device-container #device-list {
    padding-bottom: 0px; }

.device-li.located {
  display: none; }

#add-device-list {
  position: relative;
  height: 85%;
  overflow: auto;
  border-radius: 5px;
  border: 1 px solid darkblue; }
  #add-device-list > li > .list-item-content > .device-id {
    font-size: 13px; }
  #add-device-list > li > .list-item-content > .device-name {
    padding-right: 2px; }
  #add-device-list > li:hover .add-this {
    display: inline-block; }
  #add-device-list > li > .add-this {
    display: none;
    position: absolute;
    right: 70px;
    padding: 16px 20px 11px 20px;
    color: white;
    font-size: 18px; }

#show-tagged-container {
  display: inline-block;
  position: relative;
  background: #4f4f4fd6;
  padding: 3px 12px 2px 8px;
  border-radius: 5px;
  cursor: pointer;
  left: 6px; }
  #show-tagged-container > label {
    display: inline-block;
    position: relative;
    font-size: 15px; }
  #show-tagged-container > input {
    display: inline-block;
    position: relative;
    width: 14px;
    padding: 10px;
    bottom: -4px;
    margin-left: 10px; }

.device-icon-container {
  position: relative;
  width: 15px;
  text-align: center;
  top: 1px;
  margin-right: 4px; }

#add-device-search {
  width: 60%;
  margin-left: 25px;
  display: inline-block;
  border-radius: 5px;
  border: 4px solid white;
  margin-bottom: 10px; }

#add-device-list > li > .list-item-content {
  padding: 5px 10px 5px 0px;
  line-height: 0.9; }

#no-scenes-display > p > .add-scene {
  position: relative;
  top: unset;
  left: unset; }

.select-output-label {
  color: white;
  position: absolute;
  right: 0;
  top: 20px; }

.phd-output-select-container {
  width: 165px;
  max-height: 509px; }

.phd-ouput-item {
  cursor: pointer; }

.phd-ouput-item p:hover {
  color: grey; }

/*.menu-button.selected{
    box-shadow: inset 0px 0px 9px black;
}
.menu-button.selected>label{
    color:#a6b9ff;
}*/

p, span, div, .all-features-label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

#terminal-text-body {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important; }
  #terminal-text-body span {
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important; }
  #terminal-text-body div {
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important; }

.terminal-custom-ota input {
  color: white; }

.skip-part-check-terminal {
  width: 40px;
  display: inline-block;
  position: relative;
  top: 3px; }

.actionable-list {
  width: 100%; }
  .actionable-list span {
    font-weight: bold; }

.actionable-header {
  background-color: #232323; }
  .actionable-header .subheader {
    color: white;
    font-weight: bold; }

.actionable-device-item {
  border-radius: 3px;
  margin-left: 5px; }

.scene-control:active {
  background: grey !important; }

input[type="search"] {
  font-size: 13px; }

.modal-dialog {
  width: unset;
  min-width: 40%;
  max-width: 65%;
  height: 99%;
  /* height: 100%; */
  /* max-height: 77%; */
  /* overflow: auto; */
  margin: 7px auto; }

.success, .error {
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.46);
  margin-bottom: 17px;
  padding: 32px 10px 30px 5px; }

#start-ota-button {
  margin: 20px 5px 0px 0px;
  padding: 5px;
  width: 100px;
  text-align: center;
  border-radius: 5px;
  background: #025ca0;
  cursor: pointer; }

#page-wrapper {
  overflow: hidden !important; }

#features-list {
  position: relative;
  width: 100%;
  display: block;
  overflow-y: hidden;
  overflow-x: hidden;
  /* padding: 10px; */
  background: #4f4e4f; }
  #features-list > li {
    border-bottom: 1px solid rgba(31, 32, 31, 0.5);
    position: relative;
    background: #4f4e4f;
    display: block; }
    #features-list > li > .list-item-content {
      right: 3%;
      height: 100%;
      width: 91%; }
  #features-list li {
    margin-left: 4px;
    margin-right: 8px; }

.loc-settings-dropdown-button {
  margin: 0;
  height: 53px !important;
  text-align: center;
  vertical-align: middle;
  width: 5% !important;
  right: unset !important;
  border-left: 1px solid rgba(31, 32, 31, 0.5); }

#features-container {
  font-size: 1rem;
  text-align: Center;
  width: 99%;
  margin: auto;
  margin-bottom: 5px;
  /* margin-top: 10px; */
  background: #4f4f4f;
  box-shadow: 1px 1px 3px #1a191961;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  overflow-x: hidden;
  padding-top: 16px;
  margin-top: -10px;
  font-family: "district_prolight";
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out; }

#site-settings-container {
  font-size: 1rem;
  text-align: Center;
  width: 99%;
  margin: auto;
  margin-bottom: 5px;
  /* margin-top: 10px; */
  background: #4f4f4f;
  box-shadow: 1px 1px 3px #1a191961;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  overflow-x: hidden;
  padding-top: 16px;
  margin-top: -10px;
  font-family: "district_prolight";
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  padding-left: 10px;
  padding-right: 10px; }
  #site-settings-container h2 {
    font-size: 16px; }

#manually-add-switch {
  margin-top: 20px; }

#add-tiny-device-input {
  width: 75%;
  margin: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 3px;
  margin-bottom: 20px;
  max-width: 600px; }

#add-tiny-device-submit {
  border-radius: 2px; }

.site-action-button {
  background: #1f201f;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer; }

.add-action-container, .add-trigger-container {
  padding: 10px 5px 5px 20px; }

.modal {
  z-index: 1040;
  display: block;
  height: 100%;
  width: 100%;
  padding-left: 4px; }

.modal-content {
  color: white;
  background-color: #202020; }

#devices-modal .modal-content, #scenes-modal .modal-content {
  color: white;
  background-color: #333232;
  width: 100%;
  display: block;
  height: 99%;
  /* max-height: 1000px; */
  /* max-width: 800px; */
  padding: 20px; }

.search-bar {
  position: relative;
  width: 90%;
  margin: 10px auto;
  border-radius: 5px;
  border: 4px solid white;
  font-size: 13px;
  padding: 2px 7px;
  box-shadow: 0px 1px 1px 1px rgba(151, 147, 147, 0.6); }

#tagged-device-search-bar {
  width: 64%;
  left: 145px;
  margin: 0; }

#total-power {
  display: none; }

.device-modal > .modal-dialog {
  margin-top: 6%; }

.phd-device-check {
  margin-right: 10px; }

.phd-device-li {
  padding: 10px;
  cursor: pointer;
  border-top: 1px solid grey; }

#show-parental-phd {
  width: 30px;
  display: inline-block; }

.show-parental-phd {
  padding: 10px; }
  .show-parental-phd > label {
    display: inline-block;
    position: relative;
    top: 3px; }

#page-content {
  height: calc(100% - 80px);
  position: relative;
  display: block;
  width: 99%;
  margin: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

#locations-header {
  width: 100%;
  /* border-radius: 6px; */
  position: relative;
  background: #202020;
  /* box-shadow: 2px 2px 5px black; */
  top: -5px;
  height: 55px;
  z-index: 3;
  color: white;
  text-align: center;
  display: none; }

#advanced-device-settings {
  float: right;
  right: -24px;
  font-size: 35px;
  top: 30px; }

.device-dp-input {
  display: inline-block;
  width: 100%;
  margin-bottom: 0px;
  border-radius: 5px;
  /* border: 4px solid white; */
  color: white;
  background: #4f4e4f;
  padding: 2px 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.36); }

.action-trig-item-container > label {
  margin: 6px 0px;
  position: relative;
  display: block; }

.action-trig-item-container > input {
  top: 7px;
  position: relative; }

.action-input-container {
  display: none;
  width: 100%;
  position: relative; }
  .action-input-container > .settings-input {
    display: inline-block;
    margin: unset;
    margin-left: 18px;
    color: white; }
  .action-input-container > input {
    width: 83% !important;
    position: relative;
    margin: 0px !important;
    bottom: 22px; }

.settings-input > i {
  display: block;
  color: white; }

td > input {
  margin: 5px auto;
  padding: 5px;
  background: #333232;
  color: white;
  text-align: center; }

#data-table {
  font-size: 14px;
  margin: auto;
  z-index: 20; }

#device-info {
  color: white;
  max-width: 340px;
  /* margin-top: -3px; */
  /* padding-left: 10px; */
  padding-bottom: 0px;
  padding-top: 13px;
  top: 12px;
  position: relative;
  clear: both; }

#setpoint-readout, #setpoint-table-readout {
  margin: 5px 0px 5px 0px;
  padding: 8px;
  font-size: 16px;
  background: #1f201f;
  border-radius: 5px;
  display: none; }

#device-info > span {
  display: block; }

.main-device-rssi {
  padding: 5px; }

#device-header {
  border-radius: 6px;
  position: relative;
  background: #4f4e4f;
  box-shadow: 2px 2px 5px #1f201f;
  top: -5px;
  height: 80px;
  margin: auto;
  padding: 10px 56px 10px 56px;
  color: white;
  text-align: center; }

#breadcrumbs {
  cursor: pointer;
  display: none;
  font-size: 13px;
  color: #a6b9ff; }

.loc-crumb-separator {
  padding: 3px;
  font-size: 14px; }

#location-title {
  font-size: 32px;
  position: absolute;
  width: 100%;
  left: 0px;
  line-height: 1.4; }

#device-title {
  font-size: 32px;
  position: absolute;
  width: 100%;
  top: 17px;
  left: 0px;
  line-height: 1.4;
  left: -8px;
  top: 0px;
  bottom: 15px;
  line-height: 1.2; }

#location-title.with-crumbs {
  top: 12px; }

#site-header .fa {
  display: none; }

.feature-title {
  font-size: 16px;
  top: 5px;
  position: relative; }

.feature-settings > input {
  margin: 0 !important; }

.scene-title {
  position: relative;
  /* margin-bottom: 12px; */
  bottom: 15px; }

#device-title, .scene-title {
  /* top:25px; */
  font-size: 25px; }

#device-title {
  left: 5px;
  line-height: 1.2; }
  #device-title > .device-id {
    font-size: 1rem;
    position: relative;
    display: block; }

#location-data {
  position: relative;
  color: white;
  display: block;
  color: #adaaad;
  max-width: 32%;
  left: 75px;
  top: 8px;
  font-size: 14px;
  text-align: left;
  float: left; }

#sub-location-data {
  position: relative;
  color: white;
  display: block;
  bottom: 10px;
  max-width: 32%;
  font-size: 14px;
  text-align: left; }

.loc-header-data {
  display: block; }

.loc-controls-title {
  cursor: pointer; }

#site-header .fa {
  padding-right: 8px; }

#site-name {
  cursor: pointer; }

.device-main-info > span > label {
  display: block;
  color: white;
  font-size: 19.2px;
  font-size: 0.8rem;
  margin: 0; }

#devices-advanced-settings {
  cursor: pointer; }

li#no-devices-display {
  padding: 5px;
  top: 5px; }

#loc-settings-icon {
  font-size: 22px;
  float: right;
  top: 10px;
  right: 0px;
  position: absolute; }

#location-title > .component-name {
  cursor: pointer;
  top: 7px;
  position: relative;
  float: left; }

#location-title.with-crumbs > .component-name {
  top: 1px; }

#location-title > #breadcrumbs {
  margin-top: 2px; }

.scenes-hidden > i {
  margin-right: 5px; }

.scenes-hidden {
  color: white;
  margin-top: 6px;
  position: relative;
  display: block; }

#device-name-page-content, .scene-title > .component-name {
  cursor: text; }

.add-device-rssi {
  right: 40px;
  color: white;
  position: relative; }

#page-icon {
  left: 0; }

.remove-after {
  position: relative;
  top: 10px; }

#feature-list {
  position: relative;
  width: 100%;
  display: block;
  /* padding: 10px; */
  background: #4f4e4f; }

.setting-label {
  margin: 20px 0px 11px 0px;
  /* display:inline; */
  font-size: 16px !important; }

#breadcrumbs, .component-name {
  margin-left: 20px; }

.scene-title > .component-name {
  margin-left: 0px; }

#device-title .component-name {
  margin-left: 0px;
  bottom: 4px;
  position: relative; }

.button {
  float: right;
  width: 100px;
  border-radius: 3px;
  font-size: 16px;
  margin: 10px;
  border: none;
  cursor: pointer;
  background-color: #ffffff; }
  .button:hover {
    box-shadow: inset 0px 0px 3px 0px #000000b8; }

.error {
  color: #f14646; }

#content-area {
  position: relative;
  padding: 6px 0;
  margin-left: 80px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 5px; }

#on-screen-status-error {
  text-align: left;
  width: 99%;
  position: relative;
  border-radius: 3px;
  margin: auto;
  margin-bottom: 10px;
  padding: 7px 20px 7px 20px;
  display: none; }
  #on-screen-status-error span {
    color: white; }

.component-name-input {
  padding: 3px 3px 0px 10px;
  margin: 5px;
  height: 32px;
  display: inline-block;
  border-radius: 5px;
  width: unset;
  color: white;
  box-shadow: inset 0px 0px 5px #171718;
  border: none;
  background: #969494;
  position: relative; }

.scene-list-item > .list-item-content > .component-name-input, .device-list-content > .component-name-input {
  background: #269cff; }

.dev-error-lastping {
  display: block;
  padding: 2px 22px; }

.loc-error {
  margin: 10px 0px; }
  .loc-error > .fa-warning {
    font-size: 1rem;
    margin-right: 6px; }
  .loc-error svg {
    margin-right: 10px;
    margin-left: -7px; }

#status-error-dropdown {
  position: absolute;
  right: 0px;
  top: 7px;
  font-size: 1.5rem; }

#location-error-text {
  color: #D8000C;
  position: relative;
  width: 95%;
  margin-top: 12px; }

#locations-error-icon {
  color: #c1030f;
  font-size: 1.5rem; }

div#scene-edit-container {
  height: 500px;
  max-width: 1200px;
  min-width: 760px; }

div#trig-container, div#action-container {
  width: 100%;
  /* display: inline-block; */
  display: block;
  /* float: left; */
  /* min-height: inherit; */
  z-index: 10;
  /* height: 100%; */
  margin-bottom: 18px;
  /* overflow: auto; */
  overflow: hidden;
  /* margin: 1px; */
  border-radius: 3px;
  background: #dbdbdb; }

div#action-list-container, div#trigger-list-container {
  position: relative; }

.action-trig-item-container {
  padding: 15px;
  /* text-align: right; */
  position: relative;
  display: block;
  border-bottom: 1px solid #333232; }

.trig-act-label {
  font-weight: bold;
  width: 100%;
  background: #202020;
  border-radius: 3px;
  position: relative;
  padding: 10px;
  clear: both;
  text-align: center;
  box-shadow: 0px 2px 0px rgba(37, 37, 37, 0.69); }

#trig-action-container {
  height: 98%;
  display: block;
  position: relative;
  padding-right: 10px;
  width: 100%;
  top: 30px;
  overflow: auto; }

.scene-control {
  color: white;
  padding: 5px;
  background: #4f4e4f;
  margin-top: 11px;
  position: relative;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0px 3px 1px black; }

.loc-controls-title {
  font-size: 25px;
  padding: 5px;
  position: relative;
  display: block; }

#ident-device-main {
  padding: 15px;
  font-size: 30px;
  color: white;
  /* position: absolute; */
  top: 0px;
  position: relative;
  float: left; }

.feature-settings {
  border-radius: 3px;
  padding: 21px 30px 33px 30px;
  top: 4px;
  background: #2c2c2c;
  margin: 5px;
  white-space: nowrap;
  position: relative;
  text-align: left;
  box-shadow: inset 1px 1px 4px #1f201fab; }
  .feature-settings > .lock-button {
    top: -18px; }

.main-device-lastping {
  font-size: .7em; }

.device-main-info {
  display: inline-block;
  position: relative; }
  .device-main-info span {
    position: relative; }

.old-device-icon-main {
  padding-left: 3px; }

.device-data-setting > label {
  display: inine-block;
  font-size: 1.1rem; }

.device-data-setting-title {
  font-size: 15px;
  padding: 0px 0px 3px 3px;
  position: relative;
  background: #1f201f;
  display: block; }

.loc-controls-container {
  padding: 0px 10px 0px 10px; }
  .loc-controls-container.independent {
    padding: 3px 10px 10px 10px;
    border-radius: 5px;
    margin-top: 15px;
    background: #1f201f; }

.sub-loc-controls {
  background: #1f201f;
  padding: 10px;
  margin-top: 15px;
  text-align: center;
  color: white;
  border-radius: 6px;
  box-shadow: 1px 1px 2px #0202027d; }

.device-data-setting {
  background: #1f201f;
  padding: 10px;
  margin-top: 15px;
  text-align: center;
  color: white;
  border-radius: 6px;
  box-shadow: 1px 1px 2px #0202027d;
  text-align: left;
  margin-right: 3px;
  padding: 8px; }

.scene-control.slider {
  /* background: none; */
  /*box-shadow: unset;*/
  /* width: 50%; */
  /* position: relative; */
  /* height: 200px; */
  /* top: 10px; */
  /* margin-top: 30px; */
  /* float: right; */ }

.lock-button, .lock-button-scene {
  /* width: 50px; */
  font-size: 1.2rem;
  font-size: 20px;
  /* cursor: pointer; */
  /* margin-right: 12px; */
  /* position: relative; */
  position: absolute;
  top: 33px;
  right: -6px;
  z-index: 10;
  cursor: pointer;
  /* left: 68px; */
  /* top: 14px; */
  top: 57px;
  padding: 10px;
  position: relative; }

.scene-settings-options > span {
  /* width: 50%; */
  padding: 6px 10px;
  box-shadow: 1px 1px 1px black;
  /* height: 100%; */
  background: #4f4e4f;
  display: inline-block;
  position: relative;
  margin: 6px;
  border-radius: 3px; }

.slider-level {
  position: absolute;
  top: 37%;
  left: 30%; }

.cloud-sync-readouts-container {
  padding-top: 15px;
  margin-left: 5px; }

.modal-open .modal {
  /* overflow-x: hidden; */
  overflow-y: hidden; }

ul#scene-setting-dropdown {
  right: 100px;
  position: relative;
  right: 11px;
  left: unset; }

i#scene-settings-dropdown-button {
  position: relative;
  left: 83px;
  border-radius: 5px; }
  i#scene-settings-dropdown-button.fa-close {
    background: #333333;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

button {
  text-transform: none !important; }

div#scene-settings {
  width: 60px;
  position: absolute;
  top: 20px;
  right: 78px;
  font-size: 30px; }

.App {
  -webkit-transition-property: margin-right;
  transition-property: margin-right;
  -webkit-transition-duration: 250ms;
          transition-duration: 250ms; }

#finalize-feature-settings {
  background-color: #248f2d; }

#location-settings-menu {
  background-color: #4f4f4fd6;
  color: white;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 80px;
  max-height: 400px;
  overflow-y: scroll; }
  #location-settings-menu .subheader-buttons {
    display: inline;
    float: right;
    padding-right: 25px; }
    #location-settings-menu .subheader-buttons #finalize-feature-settings {
      float: none; }
  #location-settings-menu .checkbox {
    margin-right: 15px; }
  #location-settings-menu div {
    color: white;
    padding-right: 0px;
    font-size: 18px; }
  #location-settings-menu hr, #location-settings-menu p, #location-settings-menu span {
    color: white; }
  #location-settings-menu .subheader {
    font-size: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: #696969; }
    #location-settings-menu .subheader button {
      float: right; }
  #location-settings-menu .fab-container button {
    margin-left: 10px;
    margin-right: 10px; }
  #location-settings-menu #label {
    font-size: 16px; }
  #location-settings-menu .room-type-option-label {
    padding-right: 40px;
    font-size: 16px;
    padding-left: 15px; }
  #location-settings-menu .secondary-list-item-container {
    margin-top: 12px; }
    #location-settings-menu .secondary-list-item-container span, #location-settings-menu .secondary-list-item-container div {
      display: inline-block; }
    #location-settings-menu .secondary-list-item-container .not-applied {
      color: #a7a7a7; }
    #location-settings-menu .secondary-list-item-container .location-readout-container {
      margin-right: 40px; }
      #location-settings-menu .secondary-list-item-container .location-readout-container .location-readout-single {
        margin-right: 10px;
        margin-left: 10px; }
        #location-settings-menu .secondary-list-item-container .location-readout-container .location-readout-single .icon {
          margin-right: 4px; }
      #location-settings-menu .secondary-list-item-container .location-readout-container .not-applied {
        color: #a7a7a7; }
        #location-settings-menu .secondary-list-item-container .location-readout-container .not-applied span {
          color: #a7a7a7; }
  #location-settings-menu #room-type-select {
    margin-left: 55px; }

.room-type-icon {
  margin-right: 16px !important; }

#landing-page .content-container {
  width: 450px;
  margin: auto;
  margin-top: 300px; }
  #landing-page .content-container .logo-container {
    width: 350px;
    margin: auto; }
  #landing-page .content-container .text-container {
    text-align: center;
    margin-top: 20px; }
  #landing-page .content-container .primary-button {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    margin-top: 25px;
    font-size: 20px; }
  #landing-page .content-container .secondary-div {
    margin: auto;
    margin-top: 10px;
    text-align: center; }
    #landing-page .content-container .secondary-div button {
      color: white;
      text-align: center;
      background-color: transparent;
      margin: auto; }

.mobile-stepper {
  background-color: transparent !important;
  width: 50px;
  margin: auto; }

.mobile-stepper-container {
  margin-bottom: 15px; }

.stepper-container {
  max-width: 700px;
  margin: auto; }
  .stepper-container .stepper {
    background-color: transparent; }
    .stepper-container .stepper span {
      color: white !important; }
    .stepper-container .stepper svg {
      color: #4f4f4fd6; }
    .stepper-container .stepper .activeStep svg {
      color: #0074d4; }
  .stepper-container .finish-button-container button {
    background-color: #388e3c; }

.first-location .text-field-container {
  margin-top: 30x; }

.first-location #location-name-input-page-content {
  text-align: center;
  margin-top: 15px;
  font-size: 23px;
  background-color: #4f4f4fd6;
  color: white; }

.first-location .button-container {
  margin-top: 10px;
  margin-bottom: 10px; }

.first-location .room-type-container p {
  font-size: 22px;
  color: white; }

.first-location .room-type-container svg {
  margin-bottom: 0px;
  font-size: 22px;
  margin-right: 10px; }

#label svg {
  margin-right: 10px !important; }

.location-settings-menu-fab button {
  margin-right: 15px; }

/* line 17, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/*.menu-button.selected{
    box-shadow: inset 0px 0px 9px black;
}
.menu-button.selected>label{
    color:#a6b9ff;
}*/
/**
 * Fix fonts that render as bold in Firefox
 *
 * Put this near the top of your style.css
 * Before any overriding styles
 */
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400; }

/**
 * Firefox specific rule
 */
@-moz-document url-prefix() {
  body {
    font-weight: lighter !important; } }

.clearfix {
  display: inline-block; }

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }
  .clearfix:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0; }

/* close commented backslash hack */
/*This cancels the mobile long press stuff allowing us to use long press*/
.scene-button {
  /*     overflow:hidden; */
  /*     -webkit-touch-callout: none !important; */
  -webkit-user-select: none; }

#scene-button-container {
  padding-bottom: 100px; }

/* line 32, sass/partials/_layout.scss */
/* JUST A SMALL BUTTON IN THE TOP RIGHT OF A DD LI */
.tiny-dd-button {
  font-size: 16px;
  font-size: 1rem;
  width: 100px;
  height: 40px;
  float: right;
  text-align: center;
  background: #4f4f4f;
  margin: -12px 20px 7px 0px;
  color: white;
  cursor: pointer;
  letter-spacing: 1px;
  -webkit-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out; }

.dd-li-close {
  position: relative;
  /*     top:2px; */
  right: -40px; }

.locations-features-dropdown-string .dd-li-close {
  position: relative;
  right: -8px;
  top: 0px; }

/* SCENE DROPDOWN STUFF */
/* The style for the whole dropdown */
.scene-settings-dropdown {
  background-color: #3e3e3e;
  color: white;
  width: 150px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: 28px;
  top: 28px;
  z-index: 10;
  border-radius: 2%;
  vertical-align: middle;
  box-shadow: -2px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease; }
  .scene-settings-dropdown .bottom-border {
    border-bottom: 1px solid #626262; }
  .scene-settings-dropdown li {
    /*     height:40px; */
    cursor: pointer;
    vertical-align: middle; }

/* Items not at the bottom need a morder */
/* Restricts the heigh of the list items */
.scenes-dropdown-string {
  margin: 0% 0% 0% 7%;
  padding: 0% 3% 0% 4%; }

.scene-settings-dropdown .bottom-border {
  border-bottom: 1px solid #626262; }

/* END SCENE DROPDOWN STUFF */
.delete-group-icon {
  margin: 0% 0% 0% 4%;
  padding: 5px;
  position: relative;
  left: 8px; }
  .delete-group-icon:hover {
    color: red; }

/*--------------------------------------------------------------------- GENERAL CSS */
.right {
  float: right; }

.left {
  float: left; }

.bold {
  font-weight: bold; }

/* line 58, sass/partials/_layout.scss */
.success, .error {
  position: relative;
  font-size: 1rem;
  /*border: 1px solid rgba(255, 255, 255, 0.46);
  /* margin-bottom: 20px; */
  padding: 25px 20px 25px 15px; }

/* line 64, sass/partials/_layout.scss */
.success {
  color: white;
  background-color: #2d2d2d; }

/* line 6, sass/partials/_base.scss */
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }
  *:after, *:before {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility; }

/* line 15, sass/partials/_base.scss */
.clear {
  *zoom: 1; }
  .clear:before {
    content: ' ';
    display: table; }
  .clear:after {
    content: ' ';
    display: table;
    clear: both; }

/* line 20, sass/partials/_base.scss */
/* line 24, sass/partials/_base.scss */
/* line 28, sass/partials/_base.scss */
.clearer {
  clear: both;
  height: 0;
  line-height: 0;
  display: block; }

/* line 35, sass/partials/_base.scss */
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom; }

/* line 41, sass/partials/_base.scss */
a {
  text-decoration: none; }
  a:focus, a:hover, a:active {
    outline: 0; }

/* line 50, sass/partials/_base.scss */
/* line 54, sass/partials/_base.scss */
/* line 22, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
html {
  line-height: 1; }

.pointer {
  cursor: pointer; }

.clear-right {
  clear: right; }

.clear-left {
  clear: left; }

.clear-both {
  clear: both; }

#apple-header-bar {
  position: fixed;
  width: 200%;
  height: 20px;
  top: 0px;
  left: 0px;
  z-index: 999; }

.ios {
  top: 20px;
  height: 97% !important; }

/* line 59, sass/partials/_base.scss */
input:focus, button {
  /*-webkit-appearance: none;*/
  outline: 0; }

/* line 64, sass/partials/_base.scss */
iframe {
  display: block; }

/* line 68, sass/partials/_base.scss */
.hidden {
  display: none; }

.shown {
  display: block; }

a:visited {
  color: none; }

#save-success-text {
  background: white;
  z-index: 999;
  display: none;
  text-align: center;
  width: 100%;
  padding: 5px;
  text-size: 15px;
  bottom: 52px;
  left: 0px;
  box-shadow: 1px 1px 1px black;
  position: absolute;
  color: #4b89d9; }

.blue-text {
  color: #4b89d9 !important; }

.help-text-italic {
  font-style: italic; }

.help-text {
  color: #6bb9f0; }

.help-text-param {
  color: #3498Db; }

.help-text-desc {
  font-size: 80%; }

/* line 64, sass/partials/_nav.scss */
.orange {
  background: #d1952b; }

/* line 68, sass/partials/_nav.scss */
.grey {
  background: dimgrey; }

/* line 72, sass/partials/_nav.scss */
.blue {
  background: #088fff !important; }

.header-blue {
  background: #0074d4; }

.header-grey {
  background: #4f4f4f; }

.white {
  background: white; }

.green {
  color: #23c85f; }

.red {
  color: #fc4d5f; }

.yellow {
  color: yellow; }

.white-text {
  color: white; }

.grey-text {
  color: dimgrey; }

/* line 69, sass/partials/_layout.scss */
.error {
  color: #D8000C;
  background-color: #FFBABA; }

/*-------------------------------------------------------- NAVIGATION MENU GENERAL  */
/* line 1, sass/partials/_nav.scss */
#nav-icons #devices, #nav-icons #link-groups, #nav-icons #settings, #nav-icons #cloud-sync {
  display: none; }

/* line 9, sass/partials/_nav.scss 

#cloud-sync-button {
  cursor: pointer;
  width: 145px;
  margin: auto;
  margin-top: 14px;
  color: white;
  background: #4f4e4f;
  box-shadow: 0px 0px 1px black;
  border-radius: 5px;
  text-align: center;
  float: none;
  padding: 10px;
  i{
    margin-right:5px;
  }
}*/
/* line 13, sass/partials/_nav.scss */
#nav-icons a {
  margin-bottom: 3px;
  text-align: center;
  color: white;
  display: block;
  cursor: pointer;
  width: 65px;
  border-radius: 0px 3px 3px 0px;
  position: relative !important;
  height: 65px;
  line-height: 70px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out; }

#utility-nav a i {
  top: 0px; }

#devices, #locations, #cron, #sync, #scenes, #delete-devices {
  box-shadow: 3px 1px 9px rgba(37, 37, 37, 0.69);
  position: relative;
  z-index: 0; }

/* line 13, sass/partials/_nav.scss */
/* line 38, sass/partials/_nav.scss */
#nav-icons .spin {
  -webkit-animation: spin-animation 0.8s infinite linear;
          animation: spin-animation 0.8s infinite linear; }

@-webkit-keyframes spin-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

@keyframes spin-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

#nav-icons .utility-nav {
  margin-bottom: 7px; }
  #nav-icons .utility-nav a {
    color: white;
    /*inset 6px 0px 12px -7px black;*/ }
    #nav-icons .utility-nav a.active {
      position: relative;
      z-index: 997; }

#nav-icons .main-nav a.active {
  position: relative;
  z-index: 997; }

#nav-icons .utility-nav a.active {
  box-shadow: none; }

#nav-icons .utility-nav a:hover {
  position: relative;
  z-index: 996;
  box-shadow: none; }

#nav-icons .main-nav a:hover {
  position: relative;
  z-index: 996;
  box-shadow: none; }

/* line 41, sass/partials/_nav.scss */
/* line 46, sass/partials/_nav.scss */
/* line 53, sass/partials/_nav.scss */
/* line 57, sass/partials/_nav.scss */
/* line 76, sass/partials/_nav.scss */
#load-nav {
  display: none; }
  #load-nav nav {
    background: #078fff; }

/* line 79, sass/partials/_nav.scss */
.toggle-icon {
  opacity: .5;
  margin-right: 21px; }

/*-------------------------------------------------- LOC/DEV/SCENE EDIT OPTIONS GENERAL  */
.option-door {
  cursor: pointer; }

#scene-list-item-option-doorway, #device-list-item-option-doorway, #locations-list-item-option-doorway {
  height: 100%; }

#device-list-item-option-doorway {
  height: 101%;
  border-bottom: 1px solid #077ad9; }

#scene-list-item-option-doorway {
  height: 102%;
  border-bottom: 1px solid #077ad9; }

.scene-list-item .option-door i {
  position: relative;
  top: 2px; }

.delete-list-item:hover {
  color: #4f4f4f;
  cursor: pointer; }

.edit-locations-list-button:hover, .save-locations-list-button:hover {
  cursor: pointer;
  color: #4f4f4f; }

.edit-device-list-button:hover, .save-device-list-button:hover, .edit-scene-list-button:hover, .save-scene-list-button:hover, .scene-list-item {
  cursor: pointer; }

.edit-scene-list-button, .edit-device-list-button, .save-scene-list-button, .save-device-list-button {
  color: white;
  text-align: center;
  width: 40px;
  height: 100%;
  top: 0;
  background: rgba(38, 86, 127, 0.38);
  left: 85px;
  float: right;
  /* border-left: 1px solid #077ad9; */
  /* border-right: 1px solid #077ad9; */
  /* box-shadow: inset 4px 0px 9px -4px black; */ }

/*------------------------------------------- LOC EDIT DRAWER OPTIONS */
.locations-list-item-option-container {
  left: 1px;
  height: 100%;
  width: 34px;
  float: right;
  position: relative;
  z-index: 997;
  background: dimgrey; }

.sub-locations-list-item-option-container {
  background-color: #696969;
  left: 52px;
  height: 100%;
  float: right;
  position: relative; }

#locations-list-item-option-doorway {
  width: 100%;
  /* left: 5px; */
  /* background: green; */
  padding-left: 14px;
  margin-right: 0;
  font-size: 1.2rem;
  position: absolute;
  z-index: 994;
  /* left: 25px; */
  /* box-shadow: 16px 3px 13px black; */ }

.save-locations-list-button, .edit-locations-list-button {
  color: white;
  text-align: center;
  width: 40px;
  height: 100%;
  top: 0;
  left: 33px;
  background: #a09f9f;
  /* left: 83px; */
  float: right;
  z-index: 0;
  /* border-right: 1px solid black; */
  border-left: 1px solid #686868;
  position: absolute;
  /* left: 120px; */ }

.delete-locations-button {
  color: white;
  background: #ff4f4f;
  text-align: center;
  width: 40px;
  height: 100%;
  top: 0;
  float: right;
  position: absolute;
  /* left: 190px; */
  left: 113px; }

.locations-li-option-door {
  color: #959595; }

.delete-loc-button {
  left: 1%;
  color: red;
  top: 13px;
  margin-right: 8px;
  padding-bottom: 0;
  padding-top: 12px; }

.edit-loc-list-button, .done-loc-list-button {
  position: absolute;
  left: 85%; }

.edit-loc-name-list, .save-loc-name-list {
  color: white;
  font-size: 100%;
  padding-bottom: 0px;
  padding-top: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
  bottom: 0px;
  padding-right: 10px;
  z-index: 10; }

#edit-loc-name, #save-loc-name, #save-device-name, #edit-device-name {
  font-size: 50%;
  margin-left: 5px;
  text-align: right;
  position: relative;
  top: -20px;
  color: grey;
  cursor: pointer; }

/*------------------------------------------- DEV EDIT DRAWER OPTIONS */
.device-list-item-option-container {
  background-color: #088fff;
  left: 120px;
  height: 100%;
  float: right;
  position: relative;
  left: 85px; }

#device-list-item-option-doorway {
  width: 116px;
  font-size: 1.2rem; }

.device-li-option-door {
  position: absolute;
  top: 22px;
  margin-left: 10px;
  margin-right: 10px;
  color: #82c6ff; }
  .device-li-option-door:before {
    font-size: 25px; }

.edit-device-name-list, .save-device-name-list {
  position: relative;
  margin-left: 2px;
  top: 1%;
  margin-left: 10px;
  font-size: 100%;
  padding-bottom: 0px;
  padding-top: 10px;
  margin-bottom: 0px;
  bottom: 0px;
  cursor: pointer; }

.delete-device-button, .edit-device-list-button, .save-device-list-button {
  padding-top: 12px; }

#delete-devices {
  height: 100%;
  position: relative;
  bottom: 0px;
  width: 100%; }

.done-device-button {
  float: right;
  position: relative;
  top: 15px;
  cursor: pointer; }

/*------------------------------------------- SCENE EDIT DRAWER OPTIONS */
.scene-list-item-option-container {
  background-color: #088fff;
  left: 85px;
  height: 102%;
  float: right;
  position: relative;
  border-bottom: 1px solid #077ad9; }

.scene-li-option-door {
  margin: 0 15px 0 15px;
  color: #82c6ff; }
  .scene-li-option-door:before {
    font-size: 25px; }

.menu-title {
  font-size: 2rem;
  margin: 0; }

#edit-scene-name, #save-scene-name {
  font-size: 50%;
  margin-left: 5px;
  text-align: right;
  position: relative;
  cursor: pointer; }

.edit-scene {
  position: absolute;
  top: 57px;
  right: 25px; }

/*.edit-device-list-button, .done-device-list-button{
    padding:10px 10px 10px 10px;
    cursor:pointer;
}*/
/*-------------------------------------------- LOGIN NAV MENU */
.login-header {
  background: #4f4f4f9e;
  border-bottom: 1px solid #4f4f4f;
  width: 100%;
  height: 50px;
  padding-top: 10px;
  text-align: center;
  box-shadow: 1px 1px 7px rgba(37, 37, 37, 0.69); }

/* line 74, sass/partials/_layout.scss */
.user-logout, .logout {
  background: dimgrey;
  float: right;
  margin: 0px;
  cursor: pointer;
  height: 26px;
  line-height: 1.8;
  width: auto;
  box-shadow: 0px 0px 2px 1px black;
  padding: 0px 5px;
  border-radius: 3px;
  color: #ffffff;
  font-size: 0.8rem; }

.user-logout:hover, .logout:hover, .user-logout:hover, .logout:hover {
  background: #4f4f4f;
  color: white; }

#site-search-bar-container {
  width: 100%;
  position: relative;
  top: 55%;
  /* 	position: relative; */
  border-radius: 1%; }

.site-list-item:hover {
  background: #4f4f4f; }

#site-list-search-x {
  left: 88%;
  bottom: 0px;
  padding: 10px;
  cursor: pointer; }

#select-site-container {
  top: 0px;
  bottom: 0px;
  max-height: 650px;
  background-color: rgba(122, 122, 122, 0.6);
  border-radius: 1%;
  height: 600px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: background 0.15s ease;
  transition: background 0.15s ease; }

#site-list {
  cursor: all-scroll;
  -webkit-overflow-scrolling: touch; }
  #site-list li {
    color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid #626262;
    padding-top: 5px;
    cursor: pointer; }
  #site-list span {
    font-size: 15px;
    padding-left: 10px;
    cursor: pointer;
    display: block; }

#site-list-container {
  display: block;
  position: absolute;
  width: 100%; }

.no-match {
  display: none; }

#site-list-search-bar {
  display: block;
  width: 100%;
  bottom: 0px;
  position: relative;
  background-color: #d8d8d8;
  margin-bottom: -5px;
  padding: 15px;
  font-size: 22px;
  border-radius: 5px;
  height: 50px; }

/*---------------------------------- LOCATIONS NAV MENU */
#locations {
  height: 100%; }

.locations-main-header, #login-header {
  background: #4f4f4fd6;
  width: 100%;
  height: 50px;
  padding-top: 10px;
  text-align: center;
  margin-bottom: 5px; }

.refresh-data {
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
  right: 0.5%;
  /* top: 5px; */
  height: 100%;
  float: right;
  font-size: 1.7rem;
  color: white;
  /* left: 90%; */
  top: -12px;
  width: 13%;
  padding-top: 16px;
  position: relative;
  cursor: pointer; }

#locHeaderInfo {
  height: 54px;
  padding: 22px 15px 0 20px;
  border-bottom: 1px solid rgba(79, 79, 79, 0.5); }

/*------------------------------- LOCATION NAV MENU HEADER*/
#locations .menu-title {
  font-size: 1.8rem; }

.not-root-loc {
  height: 125px !important; }

#root-location-header {
  padding: 0;
  position: relative;
  top: 0px;
  width: 74%;
  font-size: 2.0rem !important; }

.root-loc-name {
  left: 0px !important;
  float: left;
  margin-left: 14px; }

.add-loc {
  font-size: 1.9rem;
  cursor: pointer;
  position: relative;
  right: 1%;
  bottom: 8px;
  z-index: 101; }

#root-location-header .edit-location-name, #root-location-header .save-location-name {
  font-size: 1rem;
  position: relative;
  top: -4px;
  left: 8px;
  color: #959595;
  cursor: pointer; }

/*------------------------------- LOCATION NAV MENU LIST*/
.loc-list-ul {
  color: white;
  overflow: auto;
  max-height: 100%;
  width: 100%;
  height: 85%;
  position: absolute;
  right: 0;
  padding: 0px 0px 100px 0px; }
  .loc-list-ul::-webkit-scrollbar {
    /* This is the magic bit */
    display: none; }

#locations ul {
  clear: left; }

.loc-list-li {
  cursor: pointer; }

.loc-list-item {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%; }

.loc-menu-back-btn-container {
  width: 100%;
  height: 50px;
  background: #4f4f4f;
  position: absolute;
  bottom: 0;
  z-index: 999;
  font-size: 2rem;
  padding: 10px 20px;
  box-shadow: 0px -2px 7px rgba(37, 37, 37, 0.69);
  opacity: 0;
  color: white; }

.loc-menu-back-btn {
  position: relative;
  z-index: 999;
  cursor: pointer; }
  .loc-menu-back-btn:hover {
    color: #252525; }

.location-path-ul {
  display: none;
  padding-left: 42px; }
  .location-path-ul li {
    width: 82%;
    border-bottom: 1px solid rgba(53, 53, 53, 0.16);
    color: rgba(255, 255, 255, 0.56); }
    .location-path-ul li i {
      padding-right: 5px; }
    .location-path-ul li:last-child {
      border-bottom: none; }

.loc-name-list {
  outline: none;
  padding: 0px 10px 0px 7px;
  position: relative;
  z-index: 100;
  left: 15px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.2s;
  transition: left 0.2s; }

.loc-list-ul li {
  position: relative;
  height: 47px;
  /* width: 375px; */
  left: 0px;
  border-bottom: 1px solid rgba(79, 79, 79, 0.7);
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.3s;
  transition: left 0.3s; }

.loc-list-ul .active-list-item {
  left: -119px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.3s;
  transition: left 0.3s; }
  .loc-list-ul .active-list-item .loc-name-list {
    left: 125px;
    -webkit-backface-visibility: hidden;
    -webkit-transition: left 0.3s;
    transition: left 0.3s; }

.loc-list-ul li:hover {
  background: #4f4f4f;
  box-shadow: inset 2px 0px 7px rgba(37, 37, 37, 0.69);
  border-bottom: none; }
  .loc-list-ul li:hover > .option-door {
    box-shadow: -8px 0px 7px -3px rgba(37, 37, 37, 0.69); }

.device-list-el:hover > .option-door, .scene-list-item:hover > .option-door {
  box-shadow: -10px 0px 9px -10px rgba(0, 0, 0, 0.36); }

.loc-list-ul > li span {
  font-size: 1.2rem; }

.info-icon {
  margin: 0px 5px 0px 5px; }

.location-info label {
  font-size: 1.5rem;
  vertical-align: middle; }

.loc-list-ul {
  overflow-x: hidden; }
  .loc-list-ul .border-right {
    border-right: 1px solid  #696969; }

.child-loc-ul li .show-children-container {
  left: 310px; }

.warning-ind {
  color: white;
  position: relative;
  left: 10px; }

.loc-with-child {
  cursor: pointer; }

.show-child-locations {
  width: 40px;
  height: 100%; }

.loc-breadcrumbs li {
  float: left;
  color: #959595;
  padding-right: 5px;
  position: relative;
  bottom: 6px;
  z-index: 998;
  cursor: pointer; }

.crumb-slash {
  font-size: 1.4rem;
  color: rgba(189, 181, 181, 0.49) !important; }

.loc-breadcrumbs {
  color: #8eb8d9;
  left: 10px;
  bottom: 11px;
  position: relative;
  clear: none !important; }

.current-crumb {
  top: 0px;
  font-size: 1.4rem; }

.loc-breadcrumbs .icon-level-up {
  color: #959595;
  padding: 16px 17px 8px 7px;
  float: left;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.loc-breadcrumbs li span, .loc-breadcrumbs li i {
  color: white; }

.loc-breadcrumbs li span:hover, .loc-breadcrumbs li span:active {
  color: #8eb8d9; }

.loc-breadcrumbs li i:hover, .loc-breadcrumbs li i:active {
  color: #8eb8d9; }

/*.border {
    border-bottom: 1px solid rgba(79,79,79,1);
    position: absolute;
    width: 98%;
    left: 27px;
    padding-left: 16px;
    float: right;
}*/
.loc-name-list, .loc-name, .device-name-list, .scene-name-list {
  cursor: text; }

.show-children-container {
  position: absolute;
  width: 79px;
  left: 327px;
  top: 0px;
  background: dimgrey;
  height: 100%;
  border-left: solid 1px #4f4f4f; }

.show-children-button {
  width: 30px;
  height: 33px;
  text-align: center;
  padding: -2px;
  position: absolute;
  top: 18px;
  right: 20px;
  color: #959595; }

.loc-list-li .location-marker {
  font-size: 1.35rem;
  padding: -2px;
  position: absolute;
  top: 10px;
  left: 28px;
  color: #959595; }

.child-1 .state-ind {
  right: 57px; }

.child-1 .lev-ind {
  left: 255px; }

.child-2 .occ-indicator {
  right: 78px; }

.child-loc-ul {
  position: absolute;
  top: 40px;
  min-height: 35px;
  margin-left: 0px;
  width: 388px;
  margin-top: 10px;
  padding-left: 17px;
  padding-bottom: 150px; }
  .child-loc-ul li {
    display: none;
    width: 373px; }

.occ-ind {
  position: absolute;
  right: 80px; }

.state-ind {
  position: absolute;
  top: 14px;
  right: 40px;
  margin-left: 9px !important; }

.daylight-ind {
  position: absolute;
  top: 14px;
  right: 60px; }

.lev-ind {
  color: white;
  font-size: 14px;
  top: 2px;
  left: 4px;
  position: relative; }

/* line 53, sass/partials/_layout.scss */
.parent-loc {
  font-size: .9rem; }

.scrollable-content-locations {
  position: relative;
  bottom: 40px;
  margin-top: 50px; }

/*------------------------------------- SCENE NAV MENU */
#scenes .header-div {
  height: auto; }

.scenes-main-header {
  background: #0074d49e;
  box-shadow: 1px 1px 3px rgba(37, 37, 37, 0.69);
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  text-align: center; }

#scene-list-item-option-doorway:last-child {
  border-bottom: none; }

.scenes-main-header .menu-title {
  /* float: left; */
  position: relative;
  /* left: 34px; */
  display: inline-block;
  top: 9px;
  width: 74%;
  font-size: 1.8rem !important; }

#scenes-search-bar-container {
  width: 100%;
  position: relative;
  top: 32%;
  padding: 0 15px;
  margin-top: 15px;
  border-radius: 1%; }

#scenes .section-title {
  padding: 7px;
  background: #0074d4;
  font-size: 1.5rem;
  /* margin-top: 5px; */
  margin-bottom: 0px;
  position: relative;
  z-index: 999;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.51); }
  #scenes .section-title.sub-loc {
    background: #088fff;
    /* padding: 14px; */
    padding: 14px 8px 14px 6px;
    box-shadow: none;
    font-weight: bold;
    margin: 10px 0px -2px 0px; }

.fixed {
  position: fixed;
  width: 441px;
  top: 105px; }

.scene-container {
  margin: 10px 0px;
  position: relative;
  display: block;
  z-index: 500;
  max-height: 600px;
  overflow: scroll;
  overflow-x: hidden; }
  .scene-container::-webkit-scrollbar {
    display: none; }

.last-of-list {
  padding-bottom: 100px;
  max-height: 575px; }

.scene-container::-webkit-scrollbar {
  display: none; }

.delete-scene-button, .delete-device-button {
  color: white;
  text-align: center;
  text-align: center;
  width: 40px;
  height: 100%;
  background: #ff4f4f;
  top: 0;
  left: 30px;
  float: right; }

.scenes-header {
  height: 160px !important; }

body.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important; }
  body.dragging * {
    cursor: -webkit-grabbing !important;
    cursor: grabbing !important; }

ul.scenes-list li.placeholder, ul.child-scene-list li.placeholder {
  position: relative;
  z-index: 999;
  top: 14px;
  left: 10px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #095390; }

ul.scenes-list {
  -webkit-overflow-scrolling: touch; }
  ul.scenes-list .placeholder-container {
    height: 44px;
    /* margin-top: 10px; */
    border-bottom: 1px solid #077ad9;
    /* padding: 10px; */ }

ul.child-scene-list .placeholder-container {
  height: 44px;
  /* margin-top: 10px; */
  border-bottom: 1px solid #077ad9;
  /* padding: 10px; */ }

ul.scenes-list li.placeholder:before, ul.child-scene-listt li.placeholder {
  position: absolute;
  /** Define arrowhead **/ }

.dragged {
  position: absolute !important;
  opacity: 0.8 !important;
  z-index: 2000 !important;
  background-color: #0074d4 !important; }
  .dragged .scene-list-item-option-container {
    display: none; }

h2.root-scenes {
  cursor: pointer; }

.add-scene {
  position: absolute;
  cursor: pointer;
  z-index: 1000;
  left: 19px;
  top: 14px;
  color: white;
  font-size: 1.6rem; }

.scenes-location-section {
  overflow: visible; }

.scenes-list .active-list-item {
  right: 85px;
  -webkit-transition: right 0.2s ease-in-out;
  transition: right 0.2s ease-in-out; }
  .scenes-list .active-list-item .scene-name-list {
    left: 110px;
    -webkit-transition: left 0.2s ease-in-out;
    transition: left 0.2s ease-in-out; }

.scene-name-list {
  left: 37px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  padding-right: 20px;
  position: relative;
  display: inline-block;
  top: 2px;
  /* clear: left; */
  /* float: left; */
  white-space: nowrap;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out; }

@-moz-document url-prefix() {
  .scene-name-list {
    top: 19px; } }

#scenes-searchbar {
  width: 65%;
  display: block;
  margin-bottom: 2%;
  margin-top: 15px;
  margin-left: 143px;
  border-radius: 5px;
  margin-top: 15px;
  padding: 2px 10px;
  border: 4px solid white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.36); }

#scenes-search-bar-x {
  left: 88%;
  padding: 10px;
  cursor: pointer;
  top: 0px; }

#scenes {
  height: 100%; }
  #scenes #delete-multiple-scenes {
    margin-right: 15px; }

#scenes-main-list {
  overflow: scroll; }

.scenes-main-list {
  overflow: auto;
  /*possibly need scroll*/
  max-height: 100%;
  width: 100%;
  /* padding-left: 13px; */
  padding-bottom: 400px;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

.scene-loc {
  font-size: .8rem; }

/*.scenes-main-list::-webkit-scrollbar {
    /* This is the magic bit 
    display: none;
}*/
.scenes-list li a, .scenes-main-list .scene-list-item a {
  color: white; }

#scenes .menu-title-loc {
  font-size: 1.8rem;
  margin: 0;
  position: relative;
  top: 10px; }

#scene-sort-div {
  position: absolute;
  width: 32%;
  /* top: 66px; */
  left: 7px; }
  #scene-sort-div h3 {
    cursor: pointer;
    font-size: .9rem;
    background: #0074d4;
    padding: 10px 9px 8px 10px;
    position: relative;
    z-index: 99;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.65); }
    #scene-sort-div h3 i {
      position: absolute;
      right: 11px; }

.scene-sort-by-list {
  display: none;
  color: white;
  font-size: 1.1rem;
  position: absolute;
  z-index: 10000;
  background: #0664b2;
  height: auto;
  width: 100%;
  left: 0px;
  top: 30px;
  box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.65);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }
  .scene-sort-by-list li {
    padding: 0px 15px 0px 15px;
    border-bottom: 1px solid #0074d4;
    line-height: 35px;
    position: relative; }

.scene-sort-selected div {
  position: absolute;
  background: #088fff;
  height: 100%;
  width: 9px;
  left: 0px;
  transition: all .2s;
  -webkit-transition: all .2s; }

.scene-sort-selected span {
  font-weight: bold; }

.scenes-sub-location-section {
  padding-left: 10px; }

.scene-sort-by-list li:not(.scene-sort-selected):hover, .device-sort-by-li:not(.device-sort-selected):hover {
  background: #0478d8; }

.scenes-lock-root, .scenes-lock {
  color: white;
  position: absolute;
  float: right;
  right: 21px;
  top: 14px;
  font-size: 1.6rem;
  cursor: pointer; }

.scene-sort-by {
  font-weight: bold; }

.scenes-lock {
  top: 14px;
  right: 20px; }

.extra-scenes {
  display: none; }

/*--------------------------------- DEVICE NAV MENU */
#devices {
  height: 100%;
  width: 100%; }
  #devices .header-div {
    margin-bottom: 10px; }
  #devices .location-button-container button {
    margin-left: 8px;
    margin-right: 8px; }
  #devices .location-button-container .done {
    background-color: #388e3c; }

/*-------------------------- DEVICE MENU HEADER */
#devices-header-title {
  font-size: 1.2rem;
  display: none; }

.header-div-bottom-border {
  border-bottom: 1px solid #077ad9;
  margin-top: 15px;
  width: 100%;
  top: 142px;
  position: absolute; }

.main-header-container {
  background: #4f4f4fd6;
  box-shadow: 1px 1px 3px rgba(37, 37, 37, 0.58);
  /* border-bottom: 1px solid rgba(7, 122, 217, 0.45); */
  width: 100%;
  height: 50px;
  text-align: center; }
  .main-header-container .menu-title {
    float: left;
    position: relative;
    display: inline-block;
    top: 9px;
    text-align: left;
    margin-left: 14px; }
  .main-header-container .menu-breadcrumbs {
    float: right;
    color: white;
    position: relative;
    display: inline-block;
    top: 19px;
    text-align: right;
    margin-right: 14px;
    font-size: 14px; }
    .main-header-container .menu-breadcrumbs .single-breadcrumb-container {
      display: inline; }
    .main-header-container .menu-breadcrumbs .single-breadcrumb-container:hover {
      cursor: pointer; }
      .main-header-container .menu-breadcrumbs .single-breadcrumb-container:hover .location-name {
        text-decoration: underline; }
    .main-header-container .menu-breadcrumbs .location-name, .main-header-container .menu-breadcrumbs .location-icon {
      display: inline-block;
      margin-left: 5px; }
    .main-header-container .menu-breadcrumbs .secondary-breadcrumb-container {
      display: inline; }
      .main-header-container .menu-breadcrumbs .secondary-breadcrumb-container .caret-right {
        margin-left: 8px;
        margin-right: 3px; }
      .main-header-container .menu-breadcrumbs .secondary-breadcrumb-container svg {
        display: inline; }
      .main-header-container .menu-breadcrumbs .secondary-breadcrumb-container div {
        display: inline; }

h2.root-devices {
  font-size: 1.5rem;
  cursor: pointer; }

.device-list-button {
  height: inherit; }

.device-extra-info {
  font-size: .8rem;
  position: absolute;
  /* left: 6px; */
  width: 201px; }

.device-name-list {
  margin-left: 5px;
  font-size: 1.2rem; }

#device-nav-list li {
  color: white;
  margin-left: 0px !important;
  /* padding: 5px; */
  /* padding-left: 5px; */
  position: relative;
  right: 0px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: right 0.2s ease-in-out;
  transition: right 0.2s ease-in-out; }

#device-nav-list .active-list-item {
  right: 80px;
  -webkit-transition: right 0.2s ease-in-out;
  transition: right 0.2s ease-in-out; }

.scenes-list .active-list-item {
  right: 80px;
  -webkit-transition: right 0.2s ease-in-out;
  transition: right 0.2s ease-in-out; }

.device-list-button {
  color: white;
  margin-left: 17px;
  position: relative;
  left: 0px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out; }

#device-nav-list .active-list-item .device-list-button {
  left: 65px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out; }

.active-blue {
  background: #0074d4 !important;
  box-shadow: inset 1px 1px 7px -2px black !important; }

#nav-icons i {
  line-height: 0 !important; }

.dev-list-hidden {
  display: none !important; }

/*.delete-device-button{
    font-size: 1.2rem;
    position: absolute;
    left: -20px;
    cursor: pointer;
    top: 17px;
    margin-right: 3px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 8px;
    padding-right: 10px;
}*/
.remove-device-icon {
  font-size: 1.4rem;
  position: absolute;
  cursor: pointer;
  color: red;
  top: 11px;
  margin-right: 3px;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 8px;
  padding-right: 10px;
  left: -20px; }

.tagged-device-list li {
  position: relative;
  height: 70px;
  border-bottom: 1px solid #077ad9;
  margin-left: 10px !important; }

#device-nav-list {
  box-shadow: -2px 0px 12px -3px black;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  max-height: 40px;
  width: 100%;
  position: relative;
  padding: 15px 5px 500px 0;
  /* padding-bottom: 100px; */ }
  #device-nav-list::-webkit-scrollbar {
    /* This is the magic bit */
    display: none; }

.device-list-info {
  left: 5px;
  top: 30%;
  width: 300px;
  margin-top: 5px;
  margin-bottom: 2px; }

.has-groups .device-list-info {
  top: 47% !important; }

.device-main-info .fa-lightbulb {
  padding-left: 12px;
  right: 20px;
  top: 0px;
  font-size: 16px;
  position: absolute;
  cursor: pointer;
  padding: 5px 5px 5px 5px;
  margin-top: 20px; }

.device-main-info .item-select {
  padding-right: 8px; }

.untagged-device-list-info {
  position: relative;
  top: 40%;
  color: #9dd0f3;
  left: 3%; }

.untagged-device-tags {
  position: absolute;
  left: 3%;
  top: 30%; }

.untagged-device-loc {
  position: absolute;
  left: 3%;
  top: 60%; }

#scan-alert-container {
  display: none;
  opacity: 1;
  width: 84vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  float: left;
  z-index: 999;
  position: fixed;
  text-align: center;
  padding-top: 130px; }

#scan-alert {
  margin-right: 0px;
  text-align: center;
  padding-top: 40px;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: green;
  width: 150px;
  height: 150px;
  z-index: 999;
  position: relative;
  border-radius: 99px;
  color: white;
  font-size: 5rem; }

#scan-alert-container span {
  font-weight: bold;
  margin: auto;
  width: 80%;
  font-size: 1.5rem;
  position: relative;
  color: white; }

.untagged-device-list-info > .device-rssi {
  left: 23px;
  top: -13px;
  position: relative;
  cursor: pointer;
  padding: 5px 5px 5px 5px;
  margin-top: 20px; }

.edit-device {
  cursor: pointer;
  position: absolute;
  top: 2%;
  left: 76%;
  font-size: 32px;
  color: white;
  padding: 0px 10px 10px 20px; }

.open-group-modal {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 24px;
  font-size: 2rem;
  color: white; }

.devices-section-title {
  margin-top: 10px;
  padding: 5px 6px 10px 0px; }

.tab:hover:not(.tab-selected) {
  background: #696969;
  box-shadow: -3px 0px 1px 0px rgba(0, 0, 0, 0.21), 2px -1px 1px 0px rgba(0, 0, 0, 0.21);
  cursor: auto;
  z-index: 999;
  -webkit-transition: all .2s;
  -webkit-backface-visibility: hidden;
  cursor: pointer; }

#sort-devices {
  position: absolute;
  /* top: 66px; */
  cursor: pointer;
  left: 14px; }

#device-sort-by-list {
  display: none;
  color: white;
  font-size: 1.1rem;
  position: absolute;
  z-index: 10000;
  background: #4f4f4fd6;
  height: auto;
  width: 100%;
  left: 0px;
  top: 30px;
  box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.42);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

#sort-devices h3 {
  font-size: 16px;
  background: #4f4f4fd6;
  padding: 8px 23px 7px 6px;
  position: relative;
  z-index: 99;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.65); }

.device-sort-by-selected {
  font-weight: bold;
  margin-left: 5px; }

#sort-devices h3 i {
  position: absolute;
  right: 9px;
  font-size: 12px;
  top: 12px; }

#device-sort-by-list i {
  font-size: .8rem;
  cursor: pointer;
  position: relative;
  z-index: 999;
  bottom: 3px; }

#device-sort-by-list li {
  padding: 0px 15px 0px 15px;
  border-bottom: 1px solid #0074d4;
  line-height: 35px;
  position: relative;
  cursor: pointer; }
  #device-sort-by-list li:active {
    background: #1d79c4; }

.device-sort-selected div {
  position: absolute;
  background: #088fff;
  height: 100%;
  width: 9px;
  left: 0px;
  transition: all .2s;
  -webkit-transition: all .2s; }

.device-sort-selected span {
  font-weight: bold;
  transition: all .2s;
  -webkit-transition: all .2s; }

#device-sort-by-list li:last-child {
  border: none; }

/* ----------------------------------------- RESTORE DEVICES NAV MENU */
#devices, #delete-devices {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

#devices {
  /* REMOVED BY BB: 11/4 */
  /* z-index: 2; */
  /* for firefox 31 */
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  position: absolute; }

#delete-devices {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  position: absolute; }

/* flip the pane when hovered */
.flipping {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transition-duration: 5s;
  /* Safari */
  /* Safari */
  /* Safari */
  /* Safari */
  transition-duration: 5s;
  /* Safari */
  transition-duration: 5s; }

.flip {
  height: 100%;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative; }

#delete-devices .header-div {
  color: white;
  bottom: 22px;
  position: relative;
  border-bottom: 1px solid #0780e3;
  background: #0074d4;
  /* border-bottom: 1px solid rgba(7, 122, 217, 0.45); */
  width: 100%;
  height: 50px;
  text-align: center;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.36); }

.deleted-device {
  color: white;
  height: 65px;
  border-bottom: 1px solid #0074d4;
  padding-left: 15px;
  cursor: pointer; }
  .deleted-device:hover {
    background: #0074d4; }
  .deleted-device:last-child {
    border-bottom: none; }

.deleted-device-back-btn {
  font-size: 1.5rem;
  color: #ffffff;
  position: relative;
  left: 10px;
  top: 13px;
  cursor: pointer; }
  .deleted-device-back-btn:hover {
    color: #73b2e7; }

#deleted-device-nav-list {
  position: relative;
  bottom: 25px; }

#deleted-devices-header-title {
  position: relative;
  top: 13px;
  font-size: 1.5rem;
  left: 97px; }

.restore-deleted-devices {
  display: block;
  width: 38px;
  position: absolute;
  left: 14px;
  top: 9px;
  cursor: pointer;
  color: white;
  font-size: 30px; }

.deleted-device-name {
  font-size: 1.1rem; }

.deleted-device-counter-container {
  background-color: #f4443f;
  opacity: 0.9;
  position: absolute;
  left: 10px;
  width: 17px;
  /* padding-bottom: 1px; */
  /* font-size: 1rem; */
  font-weight: bold;
  height: 17px;
  text-align: center;
  color: white;
  top: 11px;
  border-radius: 10px;
  /* box-shadow: 0px 1px 5px #d92229; */ }

.deleted-device-counter {
  font-size: .7rem;
  position: absolute;
  left: 5px;
  top: -2px; }

#deleted-device-nav-list li {
  border-bottom: 1px solid #077ad9;
  color: white;
  padding-left: 32px;
  height: 74px; }

.restore-device {
  font-size: .8rem;
  margin-right: 25px;
  margin-top: 10px;
  cursor: pointer; }
  .restore-device:hover {
    color: darkblue; }

/*.select-to-restore:hover{
    color: rgb(244, 208, 63);
}*/
.restore-all {
  cursor: pointer;
  position: relative;
  float: right;
  color: white; }

#deleted-devices-container {
  width: 100%;
  overflow-y: auto;
  height: 175px;
  -webkit-box-shadow: inset 1px 1px 4px #4F4F4F;
  padding: 0px;
  background-color: rgba(18, 118, 209, 0.6); }

.deleted-device-info {
  position: relative;
  top: -19px;
  left: 0px;
  font-size: .8rem; }

.devices-to-restore-counter-container {
  background-color: #f4443f;
  opacity: 0.9;
  position: absolute;
  right: 22px;
  width: 13px;
  font-weight: bold;
  height: 13px;
  text-align: center;
  color: white;
  top: 6px;
  border-radius: 10px; }

.devices-to-restore-counter {
  font-size: .6rem;
  position: absolute;
  left: 4px;
  top: -5px; }

/* ------------------------------------ TASK AND CRON NAV MENU */
#cron {
  position: relative;
  overflow: auto;
  height: 100%; }

#myTasks {
  overflow: auto;
  height: 500px;
  padding-bottom: 100px;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden; }

/* line 84, sass/partials/_nav.scss */
.task-toggle {
  font-size: 1.3rem;
  cursor: pointer;
  margin-bottom: 20px; }

#new-task-container, #current-task-container {
  font-size: 1.3rem;
  cursor: pointer;
  padding: 16px 17px 6px 17px;
  margin-top: 5px;
  clear: both; }

#new-task-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

.example {
  background: #0074d4;
  padding: 10px;
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.58); }

/* line 91, sass/partials/_nav.scss */
.task-toggle span {
  float: right; }

.tasks {
  color: #93ceff;
  font-size: 1rem; }

/* line 97, sass/partials/_nav.scss */
#cron section {
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 20px; }
  #cron section label {
    font-size: 16px;
    font-size: 1rem; }
  #cron section select, #cron section input {
    padding: 5px;
    margin-bottom: 10px; }

/* line 101, sass/partials/_nav.scss */
/* line 106, sass/partials/_nav.scss */
/*
.cron-time-hour, .cron-time-min{
	max-width: 20%;
}
*/
/* line 114, sass/partials/_nav.scss */
#myTasks li {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px; }
  #myTasks li h3 {
    margin-bottom: 20px;
    font-family: "district_probook"; }
    #myTasks li h3 em {
      font-size: 16px;
      font-size: 1rem;
      font-family: "district_prothin";
      color: rgba(255, 255, 255, 0.6); }
  #myTasks li label {
    cursor: pointer;
    float: left;
    text-transform: uppercase;
    font-size: 14.4px;
    font-size: 0.9rem; }
    #myTasks li label span {
      float: left;
      margin-right: 10px;
      line-height: 28px; }
  #myTasks li input {
    float: left;
    margin-top: 6px;
    width: 20px;
    margin-left: 20px;
    cursor: pointer; }
  #myTasks li button {
    font-size: 12.8px;
    font-size: 0.8rem;
    padding: 4px 10px;
    line-height: 1.5em; }
    #myTasks li button.delete {
      color: red; }

/* line 119, sass/partials/_nav.scss */
/* line 123, sass/partials/_nav.scss */
/* line 130, sass/partials/_nav.scss */
/* line 136, sass/partials/_nav.scss */
/* line 143, sass/partials/_nav.scss */
/* line 152, sass/partials/_nav.scss */
/* line 164, sass/partials/_nav.scss */
/* --------------------------------------------------------- CLOUD SYNC NAV MENU */
#sync {
  position: relative;
  height: 100%; }
  #sync .menu-header {
    font-size: 2.0rem;
    background: #4f4f4fd6;
    box-shadow: 1px 1px 3px rgba(37, 37, 37, 0.69);
    width: 100%;
    height: 50px;
    padding-left: 10px;
    padding-top: 7px; }
  #sync .menu-title {
    font-size: 1.8rem !important; }

#show-cloud-sync-readouts {
  margin: 30px 0px 0 14px;
  padding-left: 17px; }

.approve-check {
  position: absolute;
  top: 13px; }

#show-stale-unsaved label {
  font-size: 1rem;
  cursor: pointer; }

#list-of-stale-unsaved-devices label {
  font-size: 1.1rem; }

#show-stale-unsaved label {
  margin: 10px; }

.up-to-date {
  font-size: 1rem;
  margin: 10px; }

#unsaved-total-time {
  text-align: right;
  padding-right: 20px; }

#unsaved-list > li, #list-of-stale-unsaved-devices > li {
  border-top: .5px solid #4f4f4fd6;
  margin-left: 0px !important; }
  #unsaved-list > li:last-child, #list-of-stale-unsaved-devices > li:last-child {
    border-bottom: .5px solid #4f4f4fd6; }

#unsaved-container {
  margin-bottom: 10px;
  overflow: auto;
  height: auto;
  top: 3px;
  position: relative;
  color: white;
  display: block;
  /* max-height: 60%; */
  max-height: 72%;
  color: white;
  padding: 10px;
  -webkit-overflow-scrolling: touch; }

#stale-unsaved-container {
  max-height: 35%;
  display: block;
  position: relative;
  border-bottom: 1px solid #047ee5;
  border-top: 1px solid #047ee5;
  color: white;
  background-color: #088fff; }
  #stale-unsaved-container title {
    height: 42px;
    padding: 11px;
    position: relative;
    display: inline-block; }

#show-stale-unsaved {
  color: white;
  background-color: #088fff; }

#list-of-stale-unsaved-devices {
  max-height: 400px;
  font-size: 1rem !important;
  overflow-x: hidden;
  padding-bottom: 50px;
  overflow-y: auto;
  padding: 9px; }

#unsaved-list label {
  font-size: 1.1rem !important; }

#unsaved-list-label {
  font-size: 1.2rem;
  padding: 16px 8px 0px 6px;
  font-style: italic;
  margin: 0; }

#cron .menu-header {
  font-size: 1.8rem;
  background: #0074d49e;
  box-shadow: 1px 1px 3px rgba(37, 37, 37, 0.69);
  width: 100%;
  height: 50px;
  text-align: center;
  padding-top: 7px; }

.unsaved-display {
  position: relative;
  right: 18px; }

.saving-scene {
  margin-top: 15px; }

/* ------------------------------------------------- CONTENT AREA CSS */
/* line 28, sass/partials/_pages.scss */
#content-area header #page-title {
  font-size: 48px;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 10px; }

#settings-title {
  font-size: 48px;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 10px;
  width: 40%; }

header#scene-page-header {
  margin-bottom: 0px;
  max-width: 1200px;
  padding: 10px;
  position: relative; }

#loc-settings-icon {
  z-index: 999; }

#get-data-icon {
  z-index: 999;
  color: white; }

.overview-info {
  color: #8eb8d9;
  list-style: none;
  margin-left: 10px; }

#data-table tbody {
  box-shadow: 0px 0px 7px 2px black; }

#get-data-icon-2 {
  position: relative;
  /* right: 46px; */
  /* top: 50px; */
  padding: 10px;
  font-size: 1.5rem;
  color: white;
  z-index: 999; }

.devices-advanced-settings {
  font-size: 2.5rem;
  top: 145px;
  right: -11px;
  position: absolute;
  margin-right: 100px;
  width: 0px; }

#content-area .section-title-2-terminal {
  font-size: 16px;
  font-size: 1rem;
  text-align: Center;
  padding: 20px;
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: 10%;
  width: 80%;
  background: #4f4f4f;
  letter-spacing: 1px;
  font-family: "district_prolight";
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out; }

#content-area .section-title span {
  float: right;
  display: none;
  /* until the controls are live */ }

#content-area .controls {
  background: white;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  display: none; }
  #content-area .controls .main-controls {
    width: 75%;
    float: left; }
    #content-area .controls .main-controls .control {
      float: left;
      height: 100%;
      margin: 0 2%;
      text-align: center; }
      #content-area .controls .main-controls .control .control-name {
        text-transform: uppercase; }
  #content-area .controls .side-controls {
    width: 22%;
    float: right;
    text-transform: uppercase; }
    #content-area .controls .side-controls .add-to-favorites {
      text-transform: uppercase;
      border-bottom: 1px solid #d6d8d9;
      padding-bottom: 20px;
      margin-bottom: 30px;
      color: #2d2d2d;
      font-size: 16px;
      font-size: 1rem;
      cursor: pointer;
      font-family: "district_prolight"; }
      #content-area .controls .side-controls .add-to-favorites i {
        font-size: 32px;
        font-size: 2rem;
        margin-right: 10px;
        color: #2eb8ff;
        vertical-align: middle; }
    #content-area .controls .side-controls .control {
      clear: left;
      margin-bottom: 20px;
      line-height: 25px; }
      #content-area .controls .side-controls .control .toggle-switch {
        float: left; }
      #content-area .controls .side-controls .control label.control-name {
        font-size: 16px;
        font-size: 1rem;
        color: #2d2d2d;
        margin-left: 60px;
        cursor: pointer; }

/* line 92, sass/partials/_pages.scss */
/* line 98, sass/partials/_pages.scss */
/* line 103, sass/partials/_pages.scss */
/* line 107, sass/partials/_pages.scss */
/* line 113, sass/partials/_pages.scss */
/* line 119, sass/partials/_pages.scss */
/* line 124, sass/partials/_pages.scss */
/* line 134, sass/partials/_pages.scss */
/* line 142, sass/partials/_pages.scss */
/* line 147, sass/partials/_pages.scss */
/* line 152, sass/partials/_pages.scss */
/* line 164, sass/partials/_pages.scss */
.slider-vertical {
  width: 10px;
  background: #d6d8d9;
  border: none;
  height: 230px;
  margin: 20px auto; }
  .slider-vertical .ui-slider-handle {
    left: -5px;
    margin-bottom: -5px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background: #0074d4; }
  .slider-vertical .ui-widget-header {
    background: #3ba6ff; }

/* line 171, sass/partials/_pages.scss */
/* line 181, sass/partials/_pages.scss */
/* line 4, sass/partials/_forms.scss */
input, textarea, select, button {
  width: 95%;
  display: block;
  font-family: "district_prolight";
  color: #2d2d2d;
  font-size: 16px;
  font-size: 1rem;
  padding: 3px 5px;
  border: none;
  margin: 0;
  margin-bottom: 20px; }

.reset-pass {
  position: relative;
  cursor: pointer;
  width: 100px; }

.small-input {
  width: 15%;
  padding: 0px;
  margin-bottom: 5px;
  margin-left: 15px; }

.inline-small-input {
  width: 15%;
  padding: 0px;
  /*     margin-bottom: 5px; */
  /*     margin-left: 15px; */ }

/* line 16, sass/partials/_forms.scss */
label {
  display: block;
  color: white;
  font-size: 19.2px;
  font-size: 1.2rem;
  margin-bottom: 5px; }

button {
  border-radius: 3px;
  color: white;
  margin: 0px;
  cursor: pointer;
  line-height: 1;
  width: auto;
  padding: 5px;
  background: #0074d4; }

/* line 35, sass/partials/_forms.scss */
.orange input[type="submit"] {
  color: #d1952b; }

/* line 40, sass/partials/_forms.scss */
form {
  margin-bottom: 40px; }

/* MODERNIZR CLASSES USED FOR FEATURE DECTECTION */
/* IE 9 and below */
/* line 5, sass/partials/_ie.scss */
.no-flexbox {
  /* IE 9 only*/
  /* IE 8 and below */ }
  .no-flexbox.no-canvas {
    /* IE 8 only */
    /* IE 7 and below */ }

/* line 16, sass/partials/_ie.scss */
/* ------------------------------------------------ LOCATIONS CONTENT REA */
.loc-name {
  padding: 5px;
  outline: none;
  top: 3px; }

.loc-name-list, .device-name, .loc-name, .scene-name {
  cursor: pointer;
  font-size: 16px; }

.can-edit {
  cursor: text; }

.device-li > .list-item-content {
  text-align: left; }

.device-li > .list-item-content > .can-edit, .add-device-li {
  cursor: pointer; }

.location-info li {
  vertical-align: middle;
  clear: both; }

.location-info-toggle {
  right: 20px;
  margin-bottom: 10px; }

/*Advanced device options dropdown stuff*/
devices-advanced-settings {
  font-size: 2.0em;
  position: relative;
  position: absolute;
  /* float: right; */
  z-index: 999;
  top: 250px;
  right: 87px;
  /* left: 91%; */
  width: 0px;
  cursor: pointer; }

/* Make it easy to press */
.devices-advanced-settings i {
  padding: 10px 0px 10px 5px;
  color: white;
  cursor: pointer; }

#advanced-settings-icon.fa-close {
  background-color: #3e3e3e;
  border-radius: 5px;
  z-index: 5; }

/* The style for the whole dropdown */
.advanced-settings-dropdown {
  background-color: #3e3e3e;
  color: white;
  width: 245px;
  height: 70vh;
  max-height: 548px;
  font-size: 14px;
  display: none;
  z-index: 9998;
  position: absolute;
  top: 52px;
  right: -1px;
  border-radius: 2%;
  cursor: pointer;
  overflow: auto;
  vertical-align: middle;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease; }

.advanced-device-menu-icon {
  margin-right: 10px; }

/* Items not at the bottom need a morder */
.advanced-settings-dropdown .bottom-border {
  border-bottom: 1px solid #626262; }

.advanced-settings-dropdown li {
  /*     height:40px; */
  cursor: pointer;
  vertical-align: middle; }
  .advanced-settings-dropdown li:hover {
    /*     height:40px; */
    background-color: #686868; }

/* Restricts the heigh of the list items */
.advanced-settings-dropdown-string {
  margin: 0% 0% 0% 2%;
  padding: 0% 3% 0% 4%; }

/*end advanced dropdown menu stuff*/
/* ------------ DEVICE GROUP STUFF */
#device-groups-list li i:hover {
  color: red; }

.delete-device-group-icon {
  float: right;
  margin-right: 15px;
  margin-top: 15px; }
  .delete-device-group-icon:hover {
    color: red; }

.device-group-list-item {
  color: white;
  border-radius: 4px;
  margin-left: 8px;
  margin-top: 8px;
  background-color: #404040;
  box-shadow: inset 0px 0px 5px 2px #272727;
  width: 165px;
  text-size: 10%;
  display: inline-block;
  position: relative;
  cursor: pointer; }

.modal-device-group-list-item {
  color: white;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 5px;
  background-color: rgba(59, 59, 59, 0.9);
  box-shadow: inset 0px 0px 5px 2px #272727;
  width: 165px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  cursor: pointer; }

.group-name-display {
  margin-left: 15px;
  cursor: pointer;
  padding-right: 25px; }

#group-modal-main-content {
  position: relative; }

#current-modal-group-display {
  margin-bottom: 10px; }

.group-select-option {
  color: white;
  height: 40px;
  border-bottom: 1px solid #595959; }

#group-name-container {
  margin-top: 10px;
  background-color: rgba(59, 59, 59, 0.9);
  border-radius: 1%;
  height: 260px;
  box-shadow: inset 0px 0px 10px 5px #272727; }

#new-group-name-input {
  width: 60%;
  border: 4px solid rgba(49, 49, 49, 0.9);
  margin-top: 10px;
  display: inline-block;
  margin-bottom: 2%;
  border-radius: 5px; }

#submit-new-group {
  position: absolute;
  top: 20px;
  right: 40px;
  color: white;
  font-size: 27px;
  display: inline-block;
  float: right;
  z-index: 50;
  cursor: pointer; }

.add-group-stuff {
  display: none; }

#group-search-x {
  right: 30px;
  top: 38px;
  position: absolute; }

.group-option-delete-button {
  cursor: pointer;
  margin-right: 30px;
  margin-top: 10px;
  text-size: 30px;
  float: right;
  z-index: 10; }
  .group-option-delete-button:hover {
    color: red; }

#group-list-search-bar {
  width: 100%;
  border: 4px solid rgba(49, 49, 49, 0.9);
  display: inline-block;
  margin-bottom: 2%;
  border-radius: 5px; }

.delete-group-icon {
  float: right;
  margin-right: 10px;
  padding: 3px; }

.new-group-button:hover {
  background-color: #1c92fb; }

/*------------------END OF DEVICE GROUP STUFF*/
#device-ul {
  max-height: 60%; }

.devices-for {
  margin-bottom: 10px;
  margin-left: 15px;
  top: 6px;
  font-size: 20px;
  display: inline;
  position: absolute; }

.menu-title-devices {
  top: 35px;
  text-align: left;
  display: inline;
  position: absolute;
  text-align: left;
  float: left; }

.menu-title, .menu-title-loc {
  display: inline; }

/*------------------------------------NEW DEVICE MODAL*/
#new-device-modal .modal-content, #assoc-phd-modal .modal-content {
  background-color: rgba(49, 49, 49, 0.9);
  color: white; }

#new-device-modal .modal-footer, #assoc-phd-modal .modal-footer {
  border-color: rgba(105, 105, 105, 0.9);
  margin-top: 10px; }

#new-device-modal .modal-content .modal-header h4.modal-title {
  font-weight: 500; }

#new-device-modal .modal-header {
  border-color: rgba(105, 105, 105, 0.9); }

#assoc-phd-modal .modal-header {
  border-color: rgba(105, 105, 105, 0.9); }

#new-device-modal .btn-default {
  color: white;
  border-color: rgba(80, 80, 80, 0.9);
  background-color: rgba(49, 49, 49, 0.9); }

.new-device-list {
  background-color: rgba(49, 49, 49, 0.9);
  padding: 1%;
  border-radius: 2px;
  max-height: 400px;
  overflow: auto; }

.new-device-ul {
  -webkit-overflow-scrolling: touch;
  background-color: rgba(49, 49, 49, 0.9);
  border-radius: 2px; }

.new-device-check {
  margin-right: 20px; }

.new-device-li {
  padding-left: 15px;
  border-bottom: 1px rgba(80, 80, 80, 0.9) solid; }

.fa-check {
  color: white; }

.unselected {
  background-color: darkgrey; }

.new-device-linebreak {
  padding: 0px; }

/*new device end*/
/*----------------------------------END OF DEVICES */
#myModal .modal-content {
  background-color: #595959;
  color: white; }

#myModal .modal-footer, #myModal .modal-header {
  border-color: rgba(49, 49, 49, 0.9); }

.close {
  color: white; }

#myModal .btn-default {
  color: white;
  border-color: rgba(49, 49, 49, 0.9);
  background-color: rgba(49, 49, 49, 0.9); }

#edit-page {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c4c4c+0,595959+12,666666+25,474747+39,2c2c2c+50,000000+51,2b2b2b+76,1c1c1c+91,131313+100 */
  background: #4c4c4c;
  /* Old browsers */
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313',GradientType=0 );
  /* IE6-9 */ }

.locations-features-dropdown-string {
  text-align: left;
  padding-left: 5px; }

select#loc-template-options {
  left: 3px;
  top: 10px;
  position: relative; }

.loc-features-options {
  color: white;
  float: right;
  font-size: 2.0rem;
  position: relative;
  top: 10px;
  right: 12px;
  z-index: 20;
  cursor: pointer; }
  .loc-features-options i {
    padding: 10px 0px 10px 5px; }

.loc-features-dropdown {
  background-color: #3e3e3e;
  color: white;
  width: 145px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: -5px;
  padding: 5px;
  top: 28px;
  z-index: 10;
  border-radius: 2%;
  vertical-align: middle;
  box-shadow: -2px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease; }
  .loc-features-dropdown .bottom-border {
    border-bottom: 1px solid #626262; }

.loc-list-settings-dropdown li {
  max-height: 40px;
  cursor: pointer; }

.loc-list-settings-dropdown .bottom-border {
  border-bottom: 1px solid #626262; }

/* TODO: Hover isnt working... */
/*
.loc-list-settings-dropdown li:hover {
	background-color: #737573;
}
*/
.subtitle-dropdown {
  z-index: 999;
  position: absolute;
  padding: 10px;
  top: 8px;
  right: 14px;
  cursor: pointer;
  font-size: 24px;
  font-size: 1.7rem;
  color: white; }

#psm-config-list li {
  height: 60px;
  border-bottom: 1px solid #2d2d2d; }

.psm-check-all-button {
  padding: 10px 10px 0px 0px;
  float: right;
  margin-right: 25%;
  display: inline; }
  .psm-check-all-button label {
    color: #23c85f;
    float: right;
    margin-left: 25px; }

#psm-check-all {
  /*     float:left; */
  padding: 10px; }

.psmConfig {
  padding: 10px 0px 0px 0px;
  border-bottom: 2px solid black;
  /*     text-align: justify; */
  /*     -ms-text-justify: distribute-all-lines; */ }

.psmChoices {
  width: 20%;
  /*     vertical-align: top; */
  /*     display: inline-block; */
  font-weight: 700; }

.psmConfigLabel {
  width: 20%;
  /*     vertical-align: top; */
  /*     display: inline-block; */
  font-weight: 700;
  margin-top: 8px;
  float: left;
  text-align: left;
  margin-left: 30%; }

.psmChoices {
  float: right;
  margin-top: 8px;
  line-height: 1;
  height: 20px;
  margin-right: 30%; }

.psmON {
  float: right;
  display: inline-block;
  font-weight: 900; }

.psmOFF {
  float: right;
  display: inline-block;
  font-weight: 900;
  margin-left: 15px;
  margin-right: 30px; }

.psmON {
  margin-left: 20px; }
  .psmON input {
    -webkit-appearance: checkbox;
    border-radius: 3px;
    margin-top: 5px; }

.psmOFF input {
  -webkit-appearance: checkbox;
  border-radius: 3px;
  margin-top: 5px; }

.stretch {
  width: 100%;
  display: inline-block;
  line-height: 0; }

.ping-device {
  position: relative;
  color: black;
  left: 21px;
  top: 3px; }

.neg-top-marg {
  position: relative;
  top: -21px; }

.no-scroll-header {
  position: relative;
  top: 0px;
  padding: 9px 0px 0px 0px;
  z-index: 999;
  background: #078fff;
  width: 100%;
  max-width: 400px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 160px;
  border-bottom: 1px solid #1276d1; }

.no-scroll-header-grey {
  position: relative;
  top: 0px;
  padding: 9px 0px 0px 0px;
  z-index: 999;
  background: #078fff;
  width: 100%;
  max-width: 400px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 160px;
  border-bottom: 1px solid #1276d1;
  top: 0px;
  padding: 9px 0px 0px 0px;
  background: dimgray;
  max-width: 400px;
  margin-right: 20px;
  height: 130px;
  border-bottom: none; }

.scrollable-content {
  position: fixed;
  top: 175px;
  overflow: auto; }

.icon-label {
  font-size: .57rem;
  font-weight: 600;
  position: absolute;
  clear: both;
  letter-spacing: 0.5px; }

.icon-label-small {
  font-size: .56rem;
  font-weight: 600;
  position: absolute;
  clear: both;
  letter-spacing: 0.5px; }

.all-features-label {
  width: 100%;
  /* text-align: left; */
  /* REMOVED BY B 9/20 was breaking on mobile */
  /* position: absolute; */
  font-size: 1.8rem;
  padding: 0px 0px 10px 0px; }

.template-options {
  width: 200px; }

.features-label {
  font-size: 1.8rem; }

/*
.feature-label{
    margin:0px 20px 0px 0px;
    width:100%;
    display:block;
}
*/
#function-buttons {
  margin-top: 20px;
  width: 100%;
  font-size: 2rem;
  color: white; }
  #function-buttons i {
    padding: 20px; }

.main-list-header {
  text-align: center;
  margin-top: 15px; }

.active-loc {
  color: #86adcc; }

.ident-button, .reboot-button, .ping-button {
  float: left; }

.scene-id {
  position: relative;
  font-size: 100%;
  top: 0px;
  display: inline-block;
  padding-left: 0; }

.scene-action {
  width: 90%;
  /*     float:left; */
  text-align: left;
  /*     padding:3px 0px 0px 38px; */
  margin: 3px 0px 10px 40px;
  /*     border-bottom: 1px solid #414040; */ }

.delete-scene-action {
  padding: 0px 15px;
  cursor: pointer; }

#showall {
  cursor: pointer; }

.no-outline, .device-name {
  outline: none; }

.editable-text {
  background: #a1a1a1;
  box-shadow: inset 0px 0px 4px black;
  cursor: text;
  min-width: 40px;
  max-height: 55px;
  margin-left: 0px;
  border-radius: 2px;
  padding: 5px 0px 5px 0px;
  color: white !important; }

.scene-label {
  font-size: 30px;
  font-size: 2rem;
  /*   text-transform: uppercase; */
  margin-bottom: 10px; }

.scene-device-label {
  width: 100%;
  text-align: left;
  display: inline;
  font-size: 16px !important; }

.clear {
  clear: both; }

#scene-name {
  padding: 5px;
  outline: none; }

.action-device-icon {
  margin-right: 7px; }

.add-action, .add-trigger {
  position: absolute;
  top: 6px;
  right: 0px;
  padding: 5px 20px;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: all .2s;
  -webkit-transition: all .2s; }

.button-badge {
  position: relative;
  top: -17px;
  left: 11px;
  border-radius: 50%;
  color: #f04848;
  font-size: 1rem;
  z-index: 999;
  /*     height:16px; */
  height: 8px;
  width: 8px;
  background-color: #f04848; }

.button-badge-device {
  font-size: 1rem;
  position: absolute;
  right: 2px;
  margin-top: 5px;
  color: #f04848; }

/* line 24, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/ */
ol, ul {
  list-style: none;
  display: block;
  line-height: 275%; }

.main-device-rssi {
  cursor: pointer; }

#no-scenes-label {
  position: relative;
  top: 40px;
  height: 70px; }

.scene-list-button {
  position: relative;
  height: 51px; }

.active-main-nav {
  /*background: #007ce0; */ }

ul li div {
  vertical-align: middle; }

.settings-input {
  z-index: 999; }

/*.lock-button-scene {
    width: 50px;
    /* padding: 15px 11px 0px 10px;
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 12px;
    position: relative;
    left: 40px;
    top: 3px;
}*/
/* line 26, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 28, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
caption, th, td {
  text-align: center;
  vertical-align: middle; }

/* line 30, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
q, blockquote {
  quotes: none; }

/* line 103, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
q:before, q:after {
  content: "";
  content: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

/* line 32, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
a img {
  border: none; }

/* line 116, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
article, aside, details, figcaption, figure, footer, header, menu, nav, section, summary {
  display: block; }

/* FONT */
/* COLORS */
/* GRID SYSTEM */
/* BREAK TO MOBILE */
/* Functions */
/* FONT SIZE REM */
/* BACKGROUND RGBA */
/* BACKGROUND HEX TO RGBA */
/* DISPLAY INLINE-BLOCK FOR ALL! */
/* BOX SIZING */
/* line 1, sass/partials/_type.scss */
body {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4em;
  top: 0px;
  font-family: "district_prolight", Helvetica, Arial, sans-serif;
  color: #4f4f4f;
  left: 0px;
  position: fixed;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

#page-wrapper {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4em;
  top: 0px;
  font-family: "district_prolight", Helvetica, Arial, sans-serif;
  color: #4f4f4f;
  left: 0px;
  position: fixed;
  /* WE MODIFY OVERFLOW-Y LOCATION FEATURES GETS DROPPED DOWN - IF YOU DONT EXPLICITELY SET OVERFLOW-Y HIDDEN IN SAFARI Itll scroll */
  overflow-y: hidden;
  overflow-x: hidden;
  /* set to one lower than the nav so it sits below it but above anything else it might need to site above */
  z-index: 49;
  /* -webkit-overflow-scrolling: touch; */
  /*background: #2d2d2d;*/
  background: -webkit-linear-gradient(#313335 45%, #17191a);
  background: linear-gradient(#313335 45%, #17191a);
  height: 100vh;
  width: 100vw; }

.knob-readout {
  position: relative; }

/* line 13, sass/partials/_type.scss */
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: "district_prothin", Helvetica, Arial, sans-serif;
  line-height: 1em;
  margin-bottom: 0.4em;
  color: white; }

/* line 21, sass/partials/_type.scss */
h1 {
  font-size: 32px;
  font-size: 2rem;
  /*   margin-bottom: 0.8em; */ }

/* line 29, sass/partials/_type.scss */
h2 {
  font-size: 24px;
  font-size: 1.5rem; }

/* line 36, sass/partials/_type.scss */
h3 {
  font-size: 20.8px;
  font-size: 1.3rem; }

/* line 43, sass/partials/_type.scss */
h4 {
  font-size: 19.2px;
  font-size: 1.2rem; }

/* line 50, sass/partials/_type.scss */
h5, h6 {
  font-size: 16px;
  font-size: 1rem; }

/* global box-sizing */
/* line 3, sass/partials/_grid.scss */
.col4grid {
  width: 23%;
  float: left;
  margin-bottom: 2%; }
  .col4grid img {
    width: 100%; }

/* line 123, sass/partials/_grid.scss */
/* line 130, sass/partials/_grid.scss */
.col3grid {
  width: 32%;
  float: left;
  margin-bottom: 2%; }
  .col3grid.mid-col {
    margin-left: 2%;
    margin-right: 2%; }
  .col3grid.first-col {
    clear: left; }

/* line 146, sass/partials/_grid.scss */
/* line 156, sass/partials/_grid.scss */
/* line 172, sass/partials/_grid.scss */
.col2grid {
  width: 49%;
  float: left;
  margin-bottom: 2%;
  margin-left: 2%; }
  .col2grid.two-col-clear {
    clear: left;
    margin-left: 0; }

/* line 178, sass/partials/_grid.scss */
/**
 * onoff.css
 * Author: http://proto.io/freebies/onoff/
 * Author: Timmy Willison
 */
/* line 18, sass/partials/_onoff.scss */
.onoffswitch {
  position: relative;
  width: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  float: left; }

/* line 24, sass/partials/_onoff.scss */
.onoffswitch-checkbox {
  display: none; }

/* line 28, sass/partials/_onoff.scss */
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0px solid #999999;
  border-radius: 20px; }

/* line 35, sass/partials/_onoff.scss */
.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.1s ease-in;
  transition: margin 0.1s ease-in; }
  .onoffswitch-inner:before, .onoffswitch-inner:after {
    float: left;
    padding: 0;
    width: 50px;
    height: 26px;
    color: white;
    font: bold 14px/26px Trebuchet, Arial, sans-serif;
    box-sizing: border-box; }
  .onoffswitch-inner:before {
    content: "";
    padding-left: 10px;
    color: #FFFFFF;
    background-color: #0074d4; }
  .onoffswitch-inner:after {
    content: "";
    padding-right: 10px;
    color: #999999;
    background-color: #1d1d1d;
    text-align: right; }

/* line 41, sass/partials/_onoff.scss */
/* line 49, sass/partials/_onoff.scss */
/* line 56, sass/partials/_onoff.scss */
/* line 64, sass/partials/_onoff.scss */
.onoffswitch-switch {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 24px;
  width: 24px;
  height: 24px;
  margin: 1px;
  background: white;
  border: 0px solid #999999;
  border-radius: 20px;
  -webkit-transition: right 0.1s ease-in, box-shadow 0.1s ease-in;
  transition: right 0.1s ease-in, box-shadow 0.1s ease-in; }

/* line 79, sass/partials/_onoff.scss */
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0; }

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0; }

/* line 82, sass/partials/_onoff.scss */
/* line 1, sass/partials/_layout.scss */
body {
  background: #202020;
  /* B - too look good native iphone  */
  -webkit-overflow-scrolling: touch; }
  body #action-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: black;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9000000; }

/* line 4, sass/partials/_layout.scss */
/* line 15, sass/partials/_layout.scss */
.add-to-favorites-header {
  display: none;
  color: #d6d8d9;
  background: #1c1c1c;
  position: absolute;
  padding: 10px 15px;
  top: 0;
  right: 0;
  cursor: pointer; }
  .add-to-favorites-header i {
    font-size: 19.2px;
    font-size: 1.2rem;
    margin-right: 10px;
    color: #f9ff5a; }

/* line 25, sass/partials/_layout.scss */
/* line 32, sass/partials/_layout.scss */
.button {
  font-size: 16px;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  background: #4f4f4f;
  padding: 20px;
  color: white;
  display: block;
  margin-top: 35px;
  letter-spacing: 1px;
  -webkit-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out; }

.button-scene {
  background: #5c5c5c;
  padding: 10px;
  margin: 5px;
  position: relative;
  z-index: 999;
  /* width: 95%; */
  cursor: pointer; }

.ll-slider, .maxmin-level-slider, .occ-level-slider {
  cursor: pointer; }

/* line 48, sass/partials/_layout.scss */
.button:hover {
  background: #5c5c5c; }

.button-scene:hover {
  background: #989797; }

/* line 172, sass/partials/_nav.scss */
nav section {
  margin: 40px 0; }
  nav section .section-title {
    font-size: 22.4px;
    font-size: 1.4rem;
    padding-bottom: 10px;
    margin-bottom: 0;
    margin-right: 10px; }

/* line 175, sass/partials/_nav.scss */
/* line 185, sass/partials/_nav.scss */
.mm-menu .mm-list {
  padding: 0; }
  .mm-menu .mm-list > li {
    margin-left: 10px; }
    .mm-menu .mm-list > li a, .mm-menu .mm-list > li span {
      font-size: 19.2px;
      font-size: 1.2rem;
      color: white; }
    .mm-menu .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-noresults):after {
      margin-left: 0px; }
  .mm-menu .mm-list .mm-subtitle .mm-subclose {
    font-family: "district_prothin";
    font-size: 32px;
    font-size: 2rem;
    color: white;
    color: rgba(255, 255, 255, 0.6);
    line-height: 2.5rem;
    background: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

/* line 188, sass/partials/_nav.scss */
/* line 192, sass/partials/_nav.scss */
/* line 198, sass/partials/_nav.scss */
/* line 203, sass/partials/_nav.scss */
/* line 217, sass/partials/_nav.scss */
.mm-panel .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 19.2px;
  font-size: 1.2rem;
  color: white;
  color: rgba(255, 255, 255, 0.6); }

/* line 230, sass/partials/_nav.scss */
html.mm-opened .mm-page {
  box-shadow: none; }

.ident-device {
  cursor: pointer; }

.dp-info {
  padding-left: 10px;
  padding: 10px;
  font-size: 0.6rem;
  top: 0;
  right: 0;
  color: grey;
  cursor: pointer; }

.device-data-settings-title > .dp-info {
  position: absolute; }

/*

#page-content{
    height:100%;
    overflow:auto;
    -webkit-overflow-scrolling: touch;
}
*/
/* line 1, sass/partials/_pages.scss */
.settings-checkbox {
  height: 80px;
  padding: 0;
  margin: 0;
  vertical-align: middle; }

.setting-label::-moz-selection {
  background: rgba(0, 0, 0, 0); }

.setting-label::selection {
  background: rgba(0, 0, 0, 0); }

.fr-up, .fr-down {
  padding: 10px 5px;
  cursor: pointer; }

input[type='range'] {
  -webkit-appearance: none;
  outline: none;
  background-color: white;
  margin: 60px 0px 20px 0px;
  padding: 1px 0px;
  height: 1px;
  width: 96%;
  border-radius: 2px;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.6); }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    /*background-image:url(images/lightbulb.svg);*/
    border: none;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.6); }

input[type=range]::-moz-range-track {
  -moz-webkit-appearance: none;
  outline: none;
  background-color: white;
  height: 2px;
  width: 30%;
  border-radius: 2px;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.6); }

input[type=range]::-moz-range-thumb {
  width: 100%;
  -moz-webkit-appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #FFFFFFff;
  border: none;
  box-shadow: 1px 2px 2px 3px rgba(0, 0, 0, 0.6); }

#msie-error {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: none;
  background-color: gray;
  vertical-align: top;
  top: 0;
  left: 0; }

#msie-error-text {
  vertical-align: middle;
  text-align: center;
  font-size: 200px;
  font-weight: bold; }

.device-scroll-container {
  height: 60%; }

.scroll-container {
  overflow-y: auto;
  height: 65%;
  /* TODO:This isnt how we should be keeping this from running over the device list */
  -webkit-box-shadow: inset 1px 1px 4px #4F4F4F;
  background-color: rgba(18, 118, 209, 0.6); }

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px; }

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: rgba(54, 52, 54, 0.63); }

.new-device-list::-webkit-scrollbar {
  -webkit-appearance: none; }

.search-bar-x {
  color: grey;
  position: absolute;
  /*     right:35px; */
  /*     top:140px; */
  display: none; }

.swiper-container {
  width: 100%;
  height: 100%; }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center; }

#add-to-groups-header {
  line-height: 200%;
  /* display: none; */
  position: relative; }

.group-select-list-item {
  color: white;
  cursor: pointer;
  position: relative;
  height: 45px;
  padding-left: 11px;
  border-bottom: 1px solid #1c92fb;
  width: 100%; }

.device-selected-for-group {
  background-color: rgba(18, 118, 209, 0.6); }

#group-select-list .border-bottom {
  width: 100%;
  border-bottom: 1px solid #1c92fb; }

#tag-groups-search-bar {
  width: 68%;
  margin-top: 5px;
  margin-left: 0px;
  border-radius: 5px;
  /*display: none;*/
  margin-bottom: 12px;
  padding: 2px 15px;
  border: 4px solid white; }

#tag-groups-search-x {
  /* top: 84px; */
  /* right: 26px; */
  right: 21px;
  padding: 10px;
  cursor: pointer;
  top: 50px;
  /* position: relative; */ }

#tag-groups-container {
  /*display: none;*/
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(18, 118, 209, 0.6);
  border-radius: 1%;
  height: 136px;
  width: 100%; }

.groups-add-not-root-loc {
  top: 16%; }

input#site-email, #site-pass {
  height: 50px; }

#add-to-groups-container, #recently-deleted-devices-container {
  padding: 9px 19px 0px 19px;
  width: 100%;
  position: relative;
  height: 100%; }

.device-options-shown {
  height: 300px !important;
  -webkit-transition: all .2s;
  transition: all .2s; }

.group-selected {
  background-color: #1c92fb;
  box-shadow: inset -8px 0px 12px -10px black, inset 14px 0px 8px -14px black; }
  .group-selected:first-child {
    box-shadow: inset -8px 7px 12px -10px black, inset 14px 0px 8px -14px black; }
  .group-selected:last-child {
    box-shadow: inset -8px -6px 12px -10px black, inset 14px 0px 8px -14px black; }

#device-search-bar-container {
  width: 100%;
  position: relative;
  top: 32%;
  padding: 0 15px;
  margin-top: 15px;
  border-radius: 1%; }

#no-devices-add {
  box-shadow: -2px 0px 12px -3px black;
  height: 100%;
  position: relative; }

.loc-list-ul {
  padding-top: 7px;
  -webkit-overflow-scrolling: touch; }

.noMatch, .noDevices {
  color: white;
  padding: 10px; }

.add-to-groups-open {
  top: 87% !important; }

#add-remove-groups-button-container {
  /* display: none; */
  right: 60%;
  top: 14px;
  position: relative; }
  #add-remove-groups-button-container label {
    font-size: 1.1rem; }

#tag-device-dropdown-container label a, #tag-device-dropdown li a {
  text-decoration: none; }

#tagged-device-search-x {
  left: 88%;
  padding: 10px;
  cursor: pointer;
  top: 16px; }

#tagged-device-search-x-root {
  right: 12px;
  padding: 10px;
  cursor: pointer;
  top: 0px; }

#device-search-x {
  left: 88%;
  top: 1%;
  padding: 10px;
  cursor: pointer; }

#device-list-bottom-spacer {
  position: relative;
  height: 30px !important; }

#device-name-container {
  margin-top: 10px;
  background-color: rgba(18, 118, 209, 0.6);
  border-radius: 1%;
  height: 260px; }

#locations-dropdown-container {
  position: absolute;
  width: 40%;
  background: rgba(18, 118, 209, 0.6);
  vertical-align: middle;
  border-radius: 2%;
  box-shadow: 3px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.6s ease; }

#scenes-dropdown-container {
  position: absolute;
  width: 20%;
  background: rgba(18, 118, 209, 0.6);
  vertical-align: middle;
  border-radius: 2%;
  box-shadow: 3px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.6s ease; }

.no-devices {
  position: absolute;
  top: 60%;
  left: 26%; }

#tag-device-dropdown-container {
  position: absolute;
  width: 40%;
  background: rgba(18, 118, 209, 0.6);
  vertical-align: middle;
  border-radius: 2%;
  box-shadow: 3px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.6s ease;
  z-index: 9999; }

#tag-device-dropdown {
  background-color: #1276d1;
  color: white;
  width: 100%;
  display: none;
  /*
  	border-bottom: 1px solid blac
  	border-left: 1px solid black;
  */
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
  vertical-align: middle;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  /* 	-webkit-transition-property: ; */
  -webkit-transition: background 0.15s ease;
  transition: background 0.15s ease; }

.loc-settings-dropdown {
  background-color: #1276d1;
  color: white;
  width: 150px;
  height: 200px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: 1px;
  z-index: 10;
  /*
  	border-bottom: 1px solid black;
  	border-left: 1px solid black;
  */
  border-radius: 2%;
  vertical-align: middle;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  /* 	-webkit-transition-property: ; */
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease; }

#scenes-dropdown {
  background-color: #1276d1;
  color: white;
  width: 100%;
  display: none;
  /*
  	border-bottom: 1px solid black;
  	border-left: 1px solid black;
  */
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
  vertical-align: middle;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  /* 	-webkit-transition-property: ; */
  -webkit-transition: background 0.15s ease;
  transition: background 0.15s ease; }

.tag-device-dropdown.changed {
  background-color: rgba(18, 118, 209, 0.6); }

.current-dropdown-item a {
  position: relative;
  top: 4px;
  left: 2%;
  vertical-align: middle;
  text-align: center;
  color: white;
  float: left;
  padding: 2% 0% 0% 4%; }

.dropdown-arrow {
  font-family: FontAwesome;
  color: white;
  float: right;
  padding: 3% 4% 0% 0%; }

.dropdown-string {
  margin: 0% 0% 0% 7%;
  padding: 0% 3% 0% 4%; }

#tag-device-dropdown .bottom-border {
  border-bottom: 1px solid #5aa2fc; }

.loc-list-settings-dropdown {
  background-color: #3e3e3e;
  color: white;
  width: 150px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: 48px;
  top: 43px;
  z-index: 10;
  border-radius: 2px;
  vertical-align: middle;
  box-shadow: -3px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease; }
  .loc-list-settings-dropdown li {
    max-height: 40px;
    cursor: pointer; }

.loc-list-dropdown-string {
  margin: 0% 0% 0% 7%;
  padding: 0% 3% 0% 4%; }

.loc-list-settings-dropdown .bottom-border {
  border-bottom: 1px solid #626262; }

.locations-dropdown-string:hover {
  background-color: #1c92fb; }

.locations-list-dropdown-string {
  padding: 0% 0% 0% 8%;
  border-bottom: 1px solid #1c92fb; }
  .locations-list-dropdown-string:hover {
    background-color: #1c92fb; }

.dropdown-string .locations-dropdown-string a:visited {
  color: #E8E8E8; }

.current-dropdown-item a:visited {
  color: white; }

.dropdown-active {
  display: none; }

.tiny-item {
  margin-bottom: 5px; }

#button-container {
  margin-top: 1%; }

.tagged-device-list li {
  position: relative; }

.device-list-button:hover, .device-list-button:focus {
  color: white;
  text-decoration: none; }

.centering-block {
  height: 100%;
  float: left;
  font-size: 1.5rem;
  color: white;
  /* left: 90%; */
  /* top: -4px; */
  width: 13%;
  padding-top: 11px;
  position: relative;
  cursor: pointer; }

.group-tag-root {
  left: 73% !important;
  top: 5% !important; }

#group-devices-selector li {
  display: inline-block;
  margin: 0% 0% 0% 2%;
  padding: 0% 3% 0% 4%;
  color: white;
  background-color: #3e3e3e;
  width: 20px !important;
  height: 40px;
  cursor: pointer; }

.modal-text {
  line-height: 20px;
  padding: 10px 10px 0 0;
  margin: 30px 30px; }

.associated-scenes-list {
  cursor: pointer;
  color: white; }
  .associated-scenes-list:hover {
    color: #626262; }

/*alert modal stuff*/
#alert-modal .modal-title {
  position: relative;
  text-align: center;
  color: white;
  padding-bottom: 5px; }

#alert-modal .modal-alert-text {
  padding: 10px 10px 10px 10px; }

#alert-modal .modal-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: none;
  padding: 3px 15px 0 15px;
  color: white;
  font-size: 30px;
  margin: 0; }

#alert-modal .error-header {
  background-color: #d03939; }

#alert-modal .success-header {
  background-color: #198257; }

#alert-modal .alert-header {
  background-color: #f4d03f; }

#alert-modal .info-header {
  background-color: #088fff; }

#alert-modal .modal-text-box {
  border-radius: 1%;
  position: relative;
  height: auto;
  width: 100%;
  background-color: white;
  text-align: center;
  line-height: 40px;
  margin: 0;
  color: black; }

#alert-modal .modal-content {
  border-radius: 5px;
  width: 85%;
  margin: 30% auto; }

#alert-modal .modal-text {
  padding: 10px 10px 10px 10px; }

.alert-btn-close {
  margin-right: 15px; }

.alert-modal-body {
  padding: 0; }

#alert-modal .button-left {
  margin-right: 20px; }

#alert-modal .modal-text-box {
  margin: 0px; }

#alert-modal .modal-footer {
  border-top: 1px solid rgba(128, 128, 128, 0.33); }

.modal-failure-info {
  border-top: 1px solid rgba(128, 128, 128, 0.33);
  display: none; }
  .modal-failure-info p {
    display: block;
    height: 40px;
    text-align: center; }

.modal-text {
  margin: 0px; }

.modal-failure-text, .modal-success-text {
  margin: 10px; }

/*confirm-modal*/
#confirm-modal {
  z-index: 999; }
  #confirm-modal .modal-title {
    position: relative;
    text-align: center;
    color: white;
    padding-bottom: 5px; }
  #confirm-modal .modal-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 3px 15px 0 15px;
    color: white;
    font-size: 30px;
    margin: 0;
    background-color: #595959; }
    #confirm-modal .modal-header h2 {
      margin: 0px 0px 0px 2%;
      font-weight: bold; }
  #confirm-modal .modal-content {
    border-radius: 5px;
    width: 85%;
    max-width: 600px;
    margin: 30% auto; }
  #confirm-modal .modal-header .icon-question-sign {
    padding-right: 5px;
    margin-top: 2px; }

.confirmTrue {
  margin-right: 21px; }

.modal-confirm-message {
  padding: 15px 15px 15px 15px;
  font-size: 20px;
  border-radius: 2px;
  color: black; }

#confirm-modal .modal-footer {
  padding: 10px 0px 10px 0px;
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

/* ota modal */
#ota-modal .modal-content, #role-modal .modal-content {
  color: white;
  background-color: #595959; }

#upload-ota-file {
  color: white; }

#ota-channel-select {
  width: 20%; }

.main-nav a, .utility-nav a {
  text-decoration: none; }

.nav-image {
  height: 46px !important; }

.cloud-sync {
  /* ADDED BY B TO FIX SYNC SPINNING IN A WEIRD WAY */
  height: auto !important; }

#do-part-check {
  display: inline-block;
  -webkit-appearance: checkbox; }

#cancel-ota {
  color: red !important;
  position: relative !important;
  cursor: pointer;
  left: 40px !important; }

html {
  height: 100%;
  overflow: hidden; }

body {
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: auto;
  overflow-x: hidden; }

/*terminal modal stuff*/
#terminal-modal-content {
  position: relative;
  width: 90vw;
  height: 90vh;
  background-color: black;
  border: 1px solid #4174d0; }

#terminal-modal .modal-dialog {
  margin: 0px;
  padding: 20px; }

#the-terminal {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  padding-right: 0px; }

.data-table-in-terminal {
  font-size: 12px; }

.terminal-data-input {
  height: 30px;
  font-size: 12px;
  margin-top: 5px; }

.terminal-action-table {
  font-size: 12px;
  border: 1px solid #4174d0; }
  .terminal-action-table > caption {
    color: white;
    font-weight: bolder;
    font-size: 15px;
    margin-bottom: 5px; }
  .terminal-action-table > tr {
    border: 1px solid #4174d0;
    text-align: center !important;
    min-width: 40px;
    padding: 3px; }

.terminal-action-table th {
  text-align: center;
  border: 1px solid #4174d0;
  padding: 5px;
  font-weight: bold; }

.cancel-ota-terminal {
  margin-left: 15px;
  font-size: 20px; }

#terminal-text-body-container {
  top: 0;
  width: 100%;
  vertical-align: bottom;
  max-height: 89%;
  overflow-y: auto;
  position: relative; }

#terminal-text-body {
  color: white;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace; }

.loc-list-settings-dropdown {
  background-color: #3e3e3e;
  color: white;
  width: 150px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: 48px;
  top: 43px;
  z-index: 100;
  border-radius: 2px;
  vertical-align: middle;
  box-shadow: -3px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease; }

#close-terminal {
  position: relative;
  color: white;
  background: unset;
  z-index: 10;
  font-size: 34px;
  padding: 5px 5px; }

#terminal-user-info {
  color: white;
  font-size: 15px;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace; }

#command-text, #login-put {
  width: 65%;
  color: white;
  background-color: black;
  display: inline-block;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace; }

.terminal-ota {
  cursor: pointer;
  font-size: 25px; }

#upload-ota-file-terminal {
  color: white; }

/* New Device Modal Stuff */
#new-device-modal-content {
  position: relative;
  width: 200%;
  height: 800px;
  background-color: black;
  right: 45%;
  top: 20%;
  border: 1px solid #4174d0;
  overflow: scroll;
  /*max-height: ;*/ }

#cursor {
  font-size: 20px;
  color: white;
  -webkit-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite; }

@keyframes blink {
  from, to {
    color: transparent; }
  50% {
    color: white; } }

@-webkit-keyframes blink {
  0%, 100% {
    color: transparent; }
  50% {
    color: white; } }

#sensors, #dimmers, #others, #switchs {
  margin-bottom: 20px; }

.edit-name-header {
  text-transform: uppercase;
  font-weight: bold; }

#edit-name-modal .modal-header {
  background: #3179b4;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.edit-name-input {
  padding: 16px; }

#edit-name-modal input {
  margin: 0;
  position: relative;
  margin-right: 0px !important;
  background: rgba(128, 128, 128, 0.11);
  border: 1px solid rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  /* content: 'EDIT'; */ }

.add-loc-indiv, .add-loc-to-sub {
  position: absolute;
  color: white;
  text-align: center;
  background: #a09f9f;
  width: 40px;
  height: 100%;
  top: 0;
  z-index: 999;
  /* left: 155px; */
  float: right;
  left: 73px;
  border-right: 1px solid #686868;
  border-left: 1px solid #686868; }

.add-loc-indiv:hover, .add-loc-to-sub:hover {
  color: #4f4f4f; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%); }
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); }

.modal-backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0; }
  .modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5; }

.modal-header {
  min-height: 16.42857143px;
  padding: 15px;
  display: block;
  position: relative;
  border-bottom: 1px solid #e5e5e5; }
  .modal-header .close {
    margin-top: -2px;
    background: none;
    color: white;
    margin: 0;
    top: -34px;
    position: relative;
    font-size: 25px;
    z-index: 999; }

.modal-title {
  margin: 0;
  line-height: 1.42857143; }

.modal-body {
  position: relative;
  /* padding: 15px; */
  overflow: auto;
  display: block;
  width: 100%;
  height: 92%; }

.modal-footer {
  padding: 15px;
  text-align: right;
  position: relative;
  height: 10%;
  display: block;
  border-top: 1px solid #e5e5e5; }
  .modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px; }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px; }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.modal-footer:before {
  display: table;
  content: " "; }

.modal-footer:after {
  display: table;
  content: " ";
  clear: both; }

.glyphicon-modal-window:before {
  content: "\E237"; }

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button; }

.mobile-header {
  display: none;
  width: 240px;
  margin: auto;
  margin-top: 47px;
  margin-bottom: 20px; }
  .mobile-header p {
    margin-top: 10px;
    color: white;
    text-align: center;
    font-size: 22px; }

.root-header .mobile-header {
  display: none;
  text-align: left;
  margin: inherit;
  padding: 13px 10px 10px 14px;
  color: white; }
  .root-header .mobile-header .logo-container {
    margin-bottom: 8px; }
  .root-header .mobile-header #site-header {
    font-size: 27px;
    margin-right: 10px;
    text-align: left; }
  .root-header .mobile-header img {
    height: auto;
    width: 95px; }

.desktop-header {
  border-bottom: solid;
  border-color: #6d6d6d;
  border-width: 1px;
  margin: auto;
  margin-bottom: 40px;
  padding-top: 35px; }
  .desktop-header .logo-container {
    padding-left: 20px; }
    .desktop-header .logo-container img {
      max-width: 200px; }
  .desktop-header p {
    color: white;
    font-size: 26px;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-right: 10px;
    text-align: right; }

#site-list-container {
  min-height: 620px;
  background-color: #2d2d2d;
  padding-left: 75px;
  padding-right: 75px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: 10000;
  margin: 0; }
  #site-list-container #user-site-list-container {
    max-width: 600px;
    margin: auto; }
    #site-list-container #user-site-list-container #site-list-user-create-new {
      position: relative;
      float: left;
      padding: 15px;
      font-size: 19px;
      color: white;
      text-decoration: none;
      z-index: 999;
      cursor: pointer; }
    #site-list-container #user-site-list-container #site-list-user-logout {
      position: relative;
      float: right;
      padding: 15px;
      font-size: 19px;
      color: white;
      text-decoration: none;
      z-index: 999;
      cursor: pointer; }
  #site-list-container .desktop-header {
    margin-bottom: 140px; }
  #site-list-container #login-status-error {
    max-width: 380px;
    margin: auto; }
    #site-list-container #login-status-error .error {
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 0px;
      border-radius: 5px; }
  #site-list-container #app-version {
    position: absolute;
    bottom: 10px;
    color: grey;
    right: 75px; }
  #site-list-container #login-user-sites {
    height: 600px;
    padding-top: 5px;
    padding-left: 3px; }
    #site-list-container #login-user-sites ul {
      height: 100%;
      overflow-y: auto; }

.list-bottom-border {
  border-bottom: 1px solid #6d6d6d; }

#site-dashboard .table-container {
  max-width: 80%;
  margin: auto; }

#site-dashboard .check-circle {
  color: green; }

#site-dashboard .exclamation-circle {
  color: red; }

#login {
  min-height: 620px;
  background-color: #2d2d2d;
  padding-left: 75px;
  padding-right: 75px; }
  #login #login-status-error {
    max-width: 380px;
    margin: auto; }
    #login #login-status-error .error {
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 0px;
      border-radius: 5px; }
  #login #login-form {
    top: 20px; }
    #login #login-form input {
      background-color: #d8d8d8;
      max-width: 380px;
      margin: auto;
      margin-bottom: 10px;
      padding: 15px;
      font-size: 22px;
      text-transform: none;
      border-radius: 5px; }
  #login .submit-button-container {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px; }
    #login .submit-button-container #site-submit {
      width: 100%;
      max-width: 380px;
      margin: auto;
      background-color: #0074d4;
      color: white;
      float: none;
      margin-top: 10px; }
  #login .secondary-logins-container {
    width: 200px;
    margin: auto;
    text-align: center;
    margin-top: 23px; }
    #login .secondary-logins-container button {
      margin: auto;
      background-color: transparent; }
    #login .secondary-logins-container button, #login .secondary-logins-container a {
      margin-bottom: 4px; }
  #login #app-version {
    position: absolute;
    bottom: 10px;
    color: grey;
    right: 75px; }

#new-site-container {
  display: none;
  min-height: 620px;
  background-color: #2d2d2d;
  padding-left: 75px;
  padding-right: 75px;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: 10000;
  margin: 0; }
  #new-site-container input {
    padding: 15px;
    font-size: 22px;
    text-transform: none;
    border-radius: 5px;
    max-width: 380px;
    width: 100%;
    margin: auto;
    float: none;
    margin-bottom: 10px; }
  #new-site-container #site-name {
    background-color: #d8d8d8; }
  #new-site-container .button {
    max-width: 380px;
    margin: auto;
    float: none;
    margin-top: 10px; }
  #new-site-container #submit-new-site {
    background-color: dimgrey;
    color: white; }
  #new-site-container #submit-new-site.enabled {
    background-color: #0074d4; }
  #new-site-container .cancel {
    margin: auto;
    text-align: center;
    width: 50px;
    display: block;
    color: white;
    margin-top: 12px;
    font-size: 16px;
    cursor: pointer; }
  #new-site-container .text-explanation {
    margin: auto;
    text-align: center;
    width: auto;
    display: block;
    color: white;
    margin-bottom: 35px;
    font-size: 28px;
    line-height: 36px; }

#location-list {
  position: absolute;
  height: calc(100% - 50px);
  width: 100%; }
  #location-list button {
    height: auto;
    width: auto;
    padding: 15px; }
  #location-list .list-item-active {
    background-color: #616161; }
  #location-list .location-list-item-2 {
    padding-bottom: 8px;
    padding-top: 8px;
    height: 40px; }
  #location-list .location-list-item .location-list-chevrons {
    color: white;
    font-size: 14px; }
  #location-list .location-list-item .text-field {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 4px; }
    #location-list .location-list-item .text-field input {
      padding-bottom: 0px;
      padding-top: 2px;
      color: #BDBDBD; }
  #location-list .location-list-item .checkbox {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 4px; }
  #location-list .location-list-item .location-list-icons {
    margin-right: 0px;
    width: 1.25em; }
    #location-list .location-list-item .location-list-icons svg {
      margin: auto; }
  #location-list .location-list-item .list-action-items {
    white-space: nowrap;
    display: none; }
    #location-list .location-list-item .list-action-items button {
      height: auto;
      padding: 0px; }
    #location-list .location-list-item .list-action-items .edit, #location-list .location-list-item .list-action-items .add, #location-list .location-list-item .list-action-items .delete {
      padding: 7px;
      margin-left: 6px;
      margin-right: 6px; }
    #location-list .location-list-item .list-action-items .add:hover {
      background-color: #0074d4; }
    #location-list .location-list-item .list-action-items .delete:hover {
      background-color: #C23030; }
    #location-list .location-list-item .list-action-items svg {
      color: white;
      font-size: 14px; }
  #location-list .location-list-item .list-action-items.name-is-edit {
    display: block; }
  #location-list .location-list-item .location-list-item-2:hover > .list-action-items {
    display: block; }
  #location-list .location-list-item .location-name-container {
    padding-left: 4px !important; }
  #location-list .location-readout-container {
    margin-top: -10px;
    margin-bottom: -10px;
    width: 80px; }
    #location-list .location-readout-container span {
      margin-left: 4px; }
    #location-list .location-readout-container .light-level-li {
      float: right; }

#scene-list {
  position: absolute;
  height: calc(100% - 50px);
  width: 100%;
  overflow: auto;
  padding-bottom: 120px; }
  #scene-list .scene-list-item {
    padding-top: 5px;
    padding-bottom: 5px; }
    #scene-list .scene-list-item .text-field input {
      padding-bottom: 0px;
      padding-top: 2px;
      color: #BDBDBD; }
    #scene-list .scene-list-item .list-item-text {
      padding-left: 5px; }
      #scene-list .scene-list-item .list-item-text span {
        color: white;
        font-size: 16px; }
      #scene-list .scene-list-item .list-item-text p {
        color: white;
        font-size: 12px; }
    #scene-list .scene-list-item .checkbox {
      font-size: 14px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 4px; }
    #scene-list .scene-list-item .list-action-items {
      margin-top: -10px;
      margin-bottom: -10px;
      display: none; }
      #scene-list .scene-list-item .list-action-items button {
        height: auto;
        padding: 0px; }
      #scene-list .scene-list-item .list-action-items .edit, #scene-list .scene-list-item .list-action-items .add, #scene-list .scene-list-item .list-action-items .delete {
        padding: 7px;
        margin-left: 6px;
        margin-right: 6px; }
      #scene-list .scene-list-item .list-action-items .add:hover {
        background-color: #0074d4; }
      #scene-list .scene-list-item .list-action-items .delete:hover {
        background-color: #C23030; }
      #scene-list .scene-list-item .list-action-items svg {
        color: white;
        font-size: 14px; }
  #scene-list .scene-list-item:hover > .list-action-items {
    display: block; }
  #scene-list .scene-readout-container {
    color: white;
    margin-right: 40px; }

.location-button-container {
  position: absolute;
  bottom: 20px;
  right: 20px; }
  .location-button-container .add-root-location {
    background-color: #0074d4;
    margin-left: 15px;
    margin-right: 15px; }
  .location-button-container .add-root-location:hover {
    background-color: #338fdc; }
  .location-button-container .done {
    background-color: #248f2d; }
  .location-button-container .done:hover {
    background-color: #2a9833; }
  .location-button-container .edit-mode {
    background-color: grey; }
  .location-button-container .edit-mode:hover {
    background-color: lightgrey; }
  .location-button-container .delete-location-button {
    background-color: #C23030; }
    .location-button-container .delete-location-button svg {
      color: white; }
  .location-button-container .delete-location-button:hover {
    background-color: #f6685e; }
  .location-button-container svg {
    font-size: 18px; }

#page-content {
  overflow-x: hidden;
  padding: 25px; }
  #page-content .content-container {
    min-height: 100%;
    background-color: #4f4f4fd6;
    padding: 25px; }
    #page-content .content-container .header-container {
      display: block;
      width: 100%;
      position: relative;
      margin-left: 0px;
      margin-right: 0px; }
      #page-content .content-container .header-container h2 {
        float: left;
        font-size: 22px; }
  #page-content .onOff {
    font-size: 36px; }
    #page-content .onOff .on {
      color: yellow; }
    #page-content .onOff .off {
      color: white; }

#user-settings {
  padding: 15px;
  background-color: #4f4f4fd6;
  color: white;
  height: 100%;
  text-align: left;
  font-size: 20px; }
  #user-settings svg, #user-settings span {
    color: white; }
  #user-settings .user-info-container {
    padding-top: 5px;
    padding-bottom: 20px; }
    #user-settings .user-info-container span {
      padding-bottom: 5px;
      display: block; }
    #user-settings .user-info-container .avatar {
      margin-bottom: 15px;
      height: 50px;
      width: 50px; }
  #user-settings .settings-action-divider {
    margin-left: -15px;
    margin-right: -15px; }

.nav-icons-container {
  position: fixed;
  height: 100%;
  width: 65px;
  background-color: #4f4f4fd6; }

div[role='navigation'] {
  left: 65px !important;
  -webkit-transition-property: bottom;
  transition-property: bottom;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms; }

#nav-icons svg {
  font-size: 26px;
  color: #BDBDBD; }

#nav-icons span {
  color: #BDBDBD; }

#nav-icons a:hover svg {
  color: white; }

#nav-icons a:hover span {
  color: white; }

#nav-icons a.active svg {
  color: white; }

#nav-icons a.active span {
  color: white; }

#nav-icons #user-icon {
  position: fixed !important;
  bottom: 15px; }

.fab-container {
  position: absolute;
  bottom: 20px;
  right: 20px; }
  .fab-container .edit-mode {
    background-color: grey; }
  .fab-container .edit-mode:hover {
    background-color: lightgrey; }

#page-content .fab-container {
  position: fixed; }

.terminalContainer {
  height: 250px !important; }

.terminal-drawer .exit-button-container {
  color: black;
  position: absolute;
  right: 5px; }
  .terminal-drawer .exit-button-container button {
    color: white; }

#devices-header-div {
  -webkit-transition-property: height;
  transition-property: height;
  -webkit-transition-duration: 2s;
          transition-duration: 2s; }

.rangeslider {
  box-shadow: none; }

.rangslider:focus {
  outline: none; }

.rangeslider__handle:after {
  background-color: white !important;
  box-shadow: none !important; }

.rangeslider__handle:focus {
  outline: none !important; }

.rangeslider-horizontal {
  height: 3px; }

.rangeslider-horizontal .rangeslider__fill {
  background-color: #3e95cd;
  box-shadow: none; }

.rangeslider-vertical .rangeslider__fill {
  background-color: #3e95cd;
  box-shadow: none; }

.rangeslider__fill {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.rangeslider-vertical {
  background-color: white;
  max-width: 30px;
  border-radius: 5px; }
  .rangeslider-vertical .rangeslider__handle {
    border-radius: 5px;
    background: grey;
    width: 50px;
    height: 18px;
    left: -10px;
    border: none;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

#sync-list {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 80px;
  overflow: auto;
  height: calc(100% - 50px); }
  #sync-list span {
    color: white; }
  #sync-list .sync-status-container {
    float: right;
    padding-right: 60px; }
    #sync-list .sync-status-container .sync {
      -webkit-animation: spin-animation 0.8s infinite linear;
              animation: spin-animation 0.8s infinite linear; }

@keyframes spin-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

.location-features-text {
  display: inline-block; }

.secondary-list-item-container {
  float: right;
  margin-right: 50px; }

button > span {
  padding-top: 1px; }

.direct-controls-container .on-off-container button {
  background-color: grey;
  color: white;
  width: 90px; }
  .direct-controls-container .on-off-container button svg {
    margin-left: 10px;
    font-size: 16px; }

.direct-controls-container .on-off-container .top {
  margin-top: 15px;
  margin-bottom: 75px; }

.direct-controls-container .light-level-text {
  margin-top: 62px;
  float: left; }
  .direct-controls-container .light-level-text h1 {
    float: left; }

.empty-state {
  padding-top: 55px; }
  .empty-state .fa-lightbulb-on, .empty-state .fa-building, .empty-state .fa-lightbulb {
    font-size: 36px;
    margin-bottom: 15px; }
  .empty-state svg {
    color: white; }
  .empty-state button {
    background-color: #0074d4;
    margin-top: 10px; }

.snack-content {
  padding: 20px !important; }
  .snack-content span {
    display: inline-block; }
  .snack-content .text-container {
    padding-left: 10px; }
  .snack-content .close-icon {
    position: absolute;
    top: 12px;
    right: 18px; }
  .snack-content .snack-icon {
    font-size: 20px; }
  .snack-content h4 {
    font-weight: bold; }

.error-container {
  background-color: #d9534f !important; }

.warning-container {
  background-color: #d9534f !important; }

.info-container {
  background-color: white !important;
  color: black; }

.success-container {
  background-color: #248f2d !important; }

.location-empty-state {
  margin-bottom: 10px; }
  .location-empty-state .fa-lightbulb {
    font-size: 30px;
    margin-bottom: 20px; }
  .location-empty-state h2 span {
    display: inline-block;
    vertical-align: middle; }
    .location-empty-state h2 span .fa-arrow-left {
      font-size: 24px; }
  .location-empty-state h2 span:first-child {
    right: 20px;
    position: relative; }

.location-container {
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: #242424;
  display: block;
  padding: 25px; }
  .location-container .location-container {
    margin-bottom: 0px; }
  .location-container .status-icon-container {
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center; }

.action-overlay-container {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 50000; }
  .action-overlay-container .image-container {
    position: absolute;
    left: 50%;
    /* relative to nearest positioned ancestor or body element */
    top: 50%;
    /*  relative to nearest positioned ancestor or body element */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* relative to element's height & width */ }

.location-header-container {
  float: left; }

.settings-container {
  float: right;
  font-size: 30px;
  margin-right: 33px;
  color: white;
  margin-top: 4px;
  cursor: pointer; }

.settings-container:hover {
  color: grey; }

.root-header .desktop-header {
  margin-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px; }
  .root-header .desktop-header .logo-container img {
    max-width: 150px; }
  .root-header .desktop-header p {
    margin-top: 15px;
    margin-bottom: 15px; }

.location-menu-settings-drawer {
  position: static !important;
  position: initial !important; }

.renameSiteDialog {
  min-width: 375px; }


.editable-text {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto; }

@media screen and (max-width: 1024px) {
  #device-list .hover-large-list {
    display: block; }
  #location-list .location-list-item .list-action-items {
    display: block; }
  #scenes .list-action-items {
    display: block !important; } }

@media (min-width: 992px) {
  .device-dp-input {
    background: white;
    color: black; }
  .modal-lg {
    width: 900px; } }

@media screen and (max-width: 850px) {
  #sidebar-overlay {
    left: 65px !important; }
  .rangeslider-vertical .rangeslider__handle {
    width: 70px;
    height: 28px;
    left: -20px; } }

@media screen and (max-width: 800px) {
  .menu-title, .menu-title-loc {
    font-size: 1.5rem; }
  #location-list .location-list-item .list-action-items {
    display: block; }
  #device-list .hover-large-list {
    display: block;
    margin-top: 15px;
    margin-right: 0px;
    width: 46%; }
  #scenes .list-item-text {
    width: 156px; }
  #scenes .list-action-items {
    display: block !important;
    width: 166px; }
  .location-list-item-2 {
    padding-right: 15px !important; }
  .secondary-list-item-container {
    margin-right: 0px !important; }
  .location-readout-container {
    margin-right: 0px !important; }
    .location-readout-container span {
      font-size: 16px; }
  .location-feature-chevron-container {
    float: right; }
  .secondary-list-item-container {
    width: 100%;
    margin-left: 6px;
    float: left; }
  .page-content-container {
    height: auto;
    width: auto;
    top: calc(46px + env(safe-area-inset-top));
    bottom: 0;
    max-height: 100%;
    position: fixed;
    left: 60px;
    right: 0; }
  .loc-name {
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 132px; }
  #sync-list .sync-list-container {
    width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-flex; }
  #sync-list .sync-status-container {
    padding-right: 0px; } }

@media screen and (max-width: 800px) and (min-width: 575px) {
  #device-list .hover-large-list {
    width: 40%; } }

@media (min-width: 768px) {
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media screen and (max-width: 660px) {
  #location-data, #sub-location-data {
    line-height: 16px; }
  #login {
    padding-left: 25px;
    padding-right: 25px; } }

@media screen and (max-width: 540px) {
  .actionable-item-container li {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .actionable-list span {
    font-size: 13px; }
  .actionable-device p {
    font-size: 12px !important; }
    .actionable-device p svg {
      font-size: 12px !important; }
  #login-form {
    top: 100px; }
  #page-content {
    padding: 10px; }
  #sync .menu-title {
    font-size: 1.8rem; }
  .main-header-container .menu-header {
    height: 45px; }
  .main-header-container .menu-title {
    font-size: 1.8rem !important; }
  .locatoions-main-header {
    background: #4f4f4fd6;
    width: 100%;
    height: 42px;
    padding-top: 10px;
    text-align: center;
    margin-bottom: 0px; }
  #root-location-header {
    font-size: 1.8rem !important; }
  .device-li {
    padding: 0px; }
  .device-li .list-item-content {
    max-width: 300px; }
  .device-name {
    font-size: 15px; }
  #page-content .content-container {
    padding: 20px; }
  .location-container {
    padding: 8px; }
    .location-container h1 {
      padding: 5px 0px 0px 6px; }
  .location-empty-state .fa-lightbulb, .empty-state .fa-lightbulb {
    font-size: 25px;
    margin-bottom: 10px; }
  .location-empty-state .fa-building, .empty-state .fa-building {
    font-size: 28px; }
  h1 {
    font-size: 1.8rem; }
  h2 {
    font-size: 1.2rem !important; }
  i:hover {
    color: white; }
  .stepper-container .finish-button-container {
    width: 100%; }
    .stepper-container .finish-button-container button {
      margin: auto;
      margin-top: 25px; }
  .location-feature-list-item {
    display: inline-block !important; }
    .location-feature-list-item .location-feature-text-container {
      display: inline-block; }
  #remove-component:hover {
    color: white; }
  .desktop-header {
    display: none; }
  .mobile-header {
    display: block; }
    .mobile-header img {
      width: 240px;
      height: auto; }
  .page-container .mobile-header {
    display: block; }
  .empty-state {
    padding-top: 70px; }
  #landing-page .content-container {
    width: auto;
    margin-top: 200px; }
    #landing-page .content-container .logo-container {
      width: 250px; }
  #device-list .list-item-content {
    max-width: 300px; }
  #device-list #hover-large-list {
    max-width: 151px; }
  #device-list .text-field input {
    max-width: 75px; }
  #device-list .software {
    width: 100%;
    display: block; }
  .direct-controls-container .light-level-text {
    margin-top: 0px; }
    .direct-controls-container .light-level-text h1 {
      float: none; }
  #site-list-container {
    padding-left: 0px;
    padding-right: 0px; }
    #site-list-container .site-list-item:hover {
      background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
      background: initial; }
    #site-list-container #user-site-list-container {
      max-width: 280px; }
      #site-list-container #user-site-list-container #login-user-sites {
        max-height: 350px; }
      #site-list-container #user-site-list-container input {
        font-size: 18px; }
  #login {
    padding-left: 0px;
    padding-right: 0px; }
    #login #login-status-error {
      position: absolute;
      top: 0px;
      width: 100%;
      max-width: 100%; }
      #login #login-status-error .error {
        padding-top: 10px;
        padding-bottom: 10px; }
    #login #login-form input {
      max-width: 280px;
      font-size: 18px; }
    #login .submit-button-container {
      margin-top: 15px; }
      #login .submit-button-container #site-submit {
        max-width: 280px;
        font-size: 22px; }
    #login #app-version {
      position: fixed;
      bottom: 10px;
      color: grey;
      right: 45px; }
  .header-crumbs {
    padding: 3px;
    font-size: 11px; }
  #tag-device-container {
    height: 232px; }
  .loc-features-options {
    top: 1px;
    font-size: 22px; }
  #location-title {
    font-size: 24px;
    top: 12px; }
  .modal-body {
    height: 91%; }
  .modal-dialog {
    width: unset;
    max-width: 98%; }
  #ota-channel-select {
    width: 100%; }
  #location-title.with-crumbs {
    top: 8px; }
  #add-tiny-device-submit {
    padding: 8px 8px;
    font-size: 14px; }
  .location-list-item .text-field {
    top: 10px;
    left: 35px;
    position: absolute; }
    .location-list-item .text-field #location-name-input {
      max-width: 110px; } }

/*           MOBILE OPTIMIZED CSS                   */
@media screen and (max-width: 450px) {
  #sidebar {
    width: 100%; }
  #app-menu {
    width: auto; }
  div#on-screen-status-error {
    margin-top: 32px;
    font-size: 14px;
    line-height: 18px; }
  #nav-icons {
    right: -54px; }
  .dev-error-lastping, .dev-error-string {
    padding: 1px 0px; }
  .root-location-data {
    padding-left: 59px !important; }
  #site-function-select {
    width: 100%; }
  .edit-item-list, #edit-device-list {
    top: 16px; }
  #advanced-functions {
    float: none;
    margin-bottom: 8px; }
  #advanced-function-options {
    float: none;
    width: 86%;
    margin: auto; }
  .modal-confirm-message {
    margin-left: 0px; }
  #features-list > li > .list-item-content {
    padding-right: 40px;
    top: 4px; }
  #features-container {
    padding-top: 39px; }
  .loc-settings-dropdown-button {
    margin: 0;
    padding-top: 19px !important;
    height: 53px;
    text-align: center;
    right: -5px !important;
    vertical-align: middle;
    width: 19% !important;
    border-left: 1px solid rgba(31, 32, 31, 0.5); }
  .location-template-title {
    margin-bottom: 15px; }
  #features-list li {
    padding-top: 0px;
    padding-bottom: 5px; }
  .setting-label {
    text-align: left;
    line-height: 28px;
    margin-bottom: 15px;
    margin-top: 30px; }
  .feature-settings {
    padding: 21px 16px 30px 16px; }
    .feature-settings input[type='range'] {
      margin: 20px 0px 40px 0px; }
    .feature-settings > .lock-button {
      top: -30px; }
  .device-dp-input {
    background: white;
    color: black; }
  .list-item-content {
    width: 84%; }
  .sub-menu-dropdown {
    width: 18%; }
  .modal-body {
    height: 90%; }
  #scenes-modal .modal-body {
    height: 100%; }
  #trig-action-container {
    padding-right: 0px; }
  .modal-content {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .action-trig-item-container {
    padding: 5px 7px 2px 13px; }
  .occ-indicator {
    left: 84%; }
  #advanced-settings-icon {
    position: relative;
    top: 30px; }
  .component-name, #breadcrumbs {
    margin-left: 59px; }
  #device-title .component-name, .scene-title > .component-name {
    margin-left: 0px; }
  .device-main-info {
    text-align: left; }
  .menu-button:hover {
    color: white !important; }
  #status-error-dropdown {
    top: 17px; }
  #locations-header {
    height: 66px; }
  #page-content {
    margin-top: 6px; }
  #location-title.with-crumbs {
    top: 1px;
    line-height: 1.35;
    margin-top: 6px; }
  #breadcrumbs {
    font-size: 12px; }
  .independent {
    margin-top: 15px; }
  /* line 17, sass/partials/_pages.scss */
  #advanced-device-settings {
    top: 10px; }
  .device-data-setting-title {
    display: block; }
  #location-data {
    font-size: 10px;
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 2px;
    border-top: 1px solid rgba(79, 79, 79, 0.4);
    background-color: #1f201f;
    margin-top: 10px;
    top: 54px;
    left: 0;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    max-width: 100%;
    line-height: 1.3;
    display: inline-block; }
  #sub-location-data {
    font-size: 10px;
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 2px;
    border-top: 1px solid rgba(79, 79, 79, 0.3);
    background-color: #1f201f;
    border-radius: 0px 0px 6px 6px;
    margin-top: 10px;
    top: 5px;
    left: 0;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    max-width: 100%;
    line-height: 1.3;
    display: inline-block; }
  #loc-settings-icon {
    font-size: 30px;
    right: -20px;
    top: 1px; }
  .loc-header-data {
    display: inline-block;
    width: 33%;
    vertical-align: middle; }
  /* #cloud-sync-button {
    height: auto;
    padding: 10px;
  }*/
  #device-sort-by-list {
    top: 41px; }
  #tagged-device-search-bar {
    left: 105px; }
  #sort-devices {
    top: -4px;
    left: 7px;
    white-space: nowrap; }
    #sort-devices h3 {
      padding: 11px 23px 3px 6px;
      top: 3px;
      font-size: .9rem;
      vertical-align: middle;
      letter-spacing: -0.5px;
      height: 34px; }
  #advanced-device-settings {
    font-size: 32px; }
  .advanced-settings-dropdown {
    top: 80px; }
  .sort-by-selected {
    margin-left: 0px; }
  #device-sort-by-list span {
    font-size: .8rem; }
  #device-info {
    text-align: left;
    padding-top: 10px; }
  .template-options select {
    padding: 1px 12px; }
  #site-settings-container span, #site-settings-container label, #site-settings-container h2, #site-settings-container h3 {
    font-size: 1.0rem; }
  #features-container h3 {
    font-size: 1.0rem; }
  .all-features-label {
    font-size: 1.3rem !important; }
  #site-settings-container {
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px; }
  #show-tagged-container > label {
    font-size: 12px; }
  #show-tagged-container > input {
    bottom: -2px; }
  #add-device-search {
    margin-top: 0px;
    width: 50%;
    margin-left: 19px; }
  .success, .error {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 12px; }
  .nav-a > i {
    bottom: 7px;
    font-size: 32px; }
  #content-area {
    margin-left: 59px;
    padding-top: 7px; }
  .nav-a > span {
    font-size: 9px; }
  .button-badge {
    top: -22px;
    left: 6px;
    height: 15px;
    width: 15px; }
  #device-header {
    height: 57px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 16px; }
  .device-sort-by-selected {
    margin-left: 4px;
    letter-spacing: 0px; }
  #ident-device-main {
    font-size: 30px;
    top: -4px; }
  .tab {
    height: 78%; }
  #dimmers-tab {
    letter-spacing: -.4px; } }

@media screen and (max-width: 390px) {
  .device-list-loc {
    font-size: 0.70rem; }
  .occ-indicator {
    left: 79%; }
  .component-name-input {
    width: 134px; }
  .modal-body {
    height: 88%; }
  .devices-modal-body {
    height: 82%; } }

@media only screen and (max-height: 530px) {
  #page-wrapper {
    overflow-y: auto; } }

@media (max-height: 1050px) {
  .devices-modal-body {
    height: 85%; } }

