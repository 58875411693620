@import "main";

.config-tables{
    margin-top:5px;
    margin-left:20px;
    margin-right:20px;
    margin-bottom: 1px;
    // max-height: 1200%;
}
.top-readout, .bottom-readout{
    margin-top:10px;
    margin-left:20px;
    margin-right:20px;
    margin-bottom: 1px;
    max-height: 300px;
}
.device-detail-header{
    color: black;
    // margin-bottom: 10px;
    font-size: 16px;
}