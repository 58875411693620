@import "main";

#main-location-list{
  overflow: auto;
  overflow-x: hidden;
}

.location-page-info{
  margin-left:8px;
}
.sub-menu>.loc-list-li{
  padding-left: 10px;
}

.nav-a > span{
  bottom: 0;
  position: absolute;
  left: 2px;
  text-align: center;
  height: 15px;
  line-height: 1.3;
  font-size: 10px;
  /* background-color: #0374d4; */
  letter-spacing: 0.7px;
  width: 100%;
}

.nav-a > i {
  font-size: 38px;
  bottom: -3px;
  position: relative;
}

#remove-component:hover{
  color:#e85252;
}


.list-item-content.active>.loc-name{
  color: #a6b9ff;
}

.add-loc-button{
  position: absolute;
  right: 2%;
  padding: 10px;
  color:white;
  top: 2px;
  font-size: 1.2rem;
}


.loc-menu-setting{
  display:none;
  position:absolute;
  font-size:3rem;
  color:white;
}

#login-icon, .add-device{
  font-size: 1.7rem;
  color: white;
  cursor:pointer;
  top: 15px;
  left: 4%;
  position: absolute;
}
.add-device{
  top: 6px;
  left: 0px;
}

.edit-item-list, #edit-device-list{
  padding: 3px;
  font-size: 21px;
  top: 16px;
  cursor: pointer;
  color: white;
  right: 4%;
  position: absolute;
  z-index: 99;
}


#login{
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 10000;
  background-color: #595959;
  box-shadow:0px 1px 2px #3b3838;
}
#login a{
  display: block;
  color: white;
}

#login-form{
  position:relative;
  top:25px;
}
#login-header{
  position: relative;
  color: white;
  text-align: center;
  padding-top: 15px !important;
  font-size: 28px;
}


#site-submit{
  width: 100px;
  height: 50px;
  border-radius: 3px;
  line-height: 1;
  // background-color: #0074d4;
  color: white;
}

#set-target-light-level{
  width: 100px;
  height: 50px;
  border-radius: 3px;
  line-height: 1;
  background-color: #0074d4;
  color: white;
  padding:15px;
  font-size: 16px;
  display:none;
}

#set-target-light-level:hover{
  text-decoration: none;
}

#site-submit:hover{
  color:#a6b9ff;
}

#site-submit:active{
  box-shadow: inset 0px 0px 4px black;
  background-color:#313336;
}

input#site-email, #site-pass {
  height: 50px;
  border-radius: 3px;
}

.loc-list-item{
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.loc-list-li{
  position: relative;
  line-height:2.5;
  text-align: left;
  left: 0px;
  cursor:pointer;
  border-bottom: 1px solid rgba(65, 64, 64, 0.63);
  list-style: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  -ms-transition: left 0.3s;
  -o-transition: left 0.3s;
  transition: left 0.3s;
}

.loc-list-li > .loc-name{
  padding: 0px 20px 0px 10px;
}
.level-1 {
  background-color: #696969;
  //Adding padding here makes the first row indent the same as the second, but also makes other messes
  // padding-left:10px;
}

.level-2 {
  background-color: #5c5c5c;
}

.level-3 {
  background-color: #515151;
}

.occ-indicator{
  position: absolute;
  left: 88%;
  top: 1px;
  color: white;
  text-align:right;
  /* word-wrap: initial; */
  /* line-break: unset; */
  font-size: .83rem !important;
  width: 85px;
}

.loc-list-li > .loc-has-children{
  position: absolute;
  top:15px;
  left: 82%;
}


.child-loc-ul {
  position: relative;
  margin-left: 0px;
  width: 100%;
  padding-left: 17px;
}
.child-loc-ul {
  display:none;

}
#daylighting{
  padding-top: 20px;
  padding-bottom: 10px;
}










